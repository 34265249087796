import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface RssItem {
  title: string;
  pubDate: string;
  creator: string;
  categories: string[];
  description: string;
}

@Injectable()
export class RssService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  public getRss(url: string): Observable<RssItem[]> {
    return this.http.get(url, { responseType: 'text' }).pipe(
      map(str => new window.DOMParser().parseFromString(str, 'text/xml')),
      map(data => {
        const items: RssItem[] = [];
        const itemElements = data.querySelectorAll('item');
        itemElements.forEach(item => {
          const categories: string[] = [];
          item.querySelectorAll('category').forEach(cat => {
            categories.push(cat.textContent || '');
          });

          items.push({
            title: item.querySelector('title')?.textContent || '',
            pubDate: item.querySelector('pubDate')?.textContent || '',
            creator: item.querySelector('creator')?.textContent || '',
            categories,
            description: item.querySelector('description')?.textContent || '',
          });
        });
        return items;
      }),
    );
  }
}
