import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined } from '../utils';
import { IRestaurantTable } from '../models';
import { AuthService } from './auth.service';
import { KeyValueStorageService } from './key-value.storage.service';
import { WatchdogService } from './watchdog.service';

@UntilDestroy()
@Injectable()
export class RestaurantTableService {

  public readonly callWaiter$ = new BehaviorSubject<boolean>(false);
  public readonly callWaiterToPay$ = new BehaviorSubject<boolean>(false);
  public readonly callWaiterToRepeat$ = new BehaviorSubject<boolean>(false);
  public readonly table$ = new BehaviorSubject<IRestaurantTable | null>(null);
  public readonly batteryLevel$ = new BehaviorSubject<{ level: number; charging: boolean } | null>(null);
  private readonly storageKey = 'restaurantTable';
  private readonly logger = this.watchdog.tag('Restaurant Table Service', 'green');

  constructor(
    private readonly auth: AuthService,
    private readonly watchdog: WatchdogService,
    private readonly keyValueStorage: KeyValueStorageService,
  ) {
    this.getData().pipe(
      untilDestroyed(this),
    ).subscribe((table) => {
      this.logger.info('Table data initialized', table);
      this.table$.next(table);
    });

    this.auth.logouted$.pipe(
      tap(() => this.stopAllCallWaiter()),
      switchMap(() => this.clearData()),
      tap(() => this.logger.info('Cleared table data on logout')),
      untilDestroyed(this),
    ).subscribe();
  }

  public stopAllCallWaiter(): void {
    this.logger.debug('Stop all call waiter');

    this.callWaiter$.next(false);
    this.callWaiterToPay$.next(false);
    this.callWaiterToRepeat$.next(false);
  }

  public getData(): Observable<IRestaurantTable> {
    return this.keyValueStorage.get<IRestaurantTable>(this.storageKey).pipe(
      filter(isDefined),
      map((entry) => entry.value),
    );
  }

  public updateData(table: IRestaurantTable): Observable<IRestaurantTable> {
    return this.keyValueStorage.set({
      key: this.storageKey,
      value: table,
    }).pipe(
      map((entry) => entry.value),
      tap((table) => this.table$.next(table)),
      tap((table) => this.logger.debug('Table data updated', table)),
    );
  }

  public clearData(): Observable<unknown> {
    return this.keyValueStorage.delete(this.storageKey).pipe(
      tap(() => this.table$.next(null)),
    );
  }

}
