import { Inject, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

import { CORE_API_BASE_URL } from '../../core.tokens';

export const provideAuthInterceptor = () => (
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  }
);

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    @Inject(CORE_API_BASE_URL) private readonly apiUrl: string,
    private auth: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.auth.getToken();

    request = request.clone({
      url: this.setUrl(request.url),
    });

    if (token && request.url.startsWith(this.apiUrl)) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`,
        },
      });
    }

    return next.handle(request);
  }

  private setUrl(url: string): string {
    if (url.startsWith('@api_host')) {
      return url.replace('@api_host', this.apiUrl);
    }

    return url;
  }

}
