import { AfterContentInit, Component, DestroyRef, Inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SafeUrl } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import {
  CORE_FEATURE_TOGGLE,
  CoreFeatureToggle,
  InvoiceQrcodeImageService,
  NetworkService,
  RestaurantTableService,
  WebsocketService,
  WidgetUiConfigService,
} from '@core';

@Component({
  standalone: true,
  selector: 'app-invoice-tab',
  templateUrl: './invoice-tab.component.html',
  imports: [
    TranslateModule,
    NgIf,
  ],
  styleUrls: ['./invoice-tab.component.scss'],
})
export class InvoiceTabComponent implements AfterContentInit {

  qrUrl: SafeUrl | null = null;
  tableName: string | null = null;

  constructor(
    @Inject(CORE_FEATURE_TOGGLE) private readonly featureToggle: CoreFeatureToggle,
    private readonly destroyRef: DestroyRef,
    private readonly webSocketService: WebsocketService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly invoiceQrcodeImageService: InvoiceQrcodeImageService,
    private readonly network: NetworkService,
    private readonly widgetUiConfigService: WidgetUiConfigService,
  ) {}

  get widgetUi(): WidgetUiConfigService {
    return this.widgetUiConfigService;
  }

  get isOnline(): boolean {
    return this.network.isOnline;
  }

  get isWebSocketConnected(): boolean {
    return this.webSocketService.isConnected;
  }

  get withCallWaiterToPayFeatures(): boolean {
    const withCallWaiterToPay = this.restaurantTableService.table$.getValue()?.features?.withCallWaiterToPay;

    if (withCallWaiterToPay !== undefined) {
      return withCallWaiterToPay;
    }
    return true;
  }

  get withCallWaiterToPay(): boolean {
    return this.featureToggle.withCallWaiterToPay && (
      this.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  ngAfterContentInit(): void {
    this.restaurantTableService.table$.pipe(
      map(table => table?.tableName ?? null),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((tableName) => {
      this.tableName = tableName;
    });

    this.invoiceQrcodeImageService.url$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((grUrl) => {
      this.qrUrl = grUrl;
    });
  }

  handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiter$.next(!this.callWaiter);
    }
  }

  handlerCallWaiterToPay($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiterToPay$.next(!this.callWaiterToPay);
    }
  }

  handlerCallWaiterToRepeat($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiterToRepeat$.next(!this.callWaiterToRepeat);
    }
  }

}
