import { Directive, Inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CORE_USER_ACTIVITY_SECTION } from '../services';

@Directive({
  selector: '[coreActivitySection]',
  standalone: true,
  providers: [
    {
      provide: CORE_USER_ACTIVITY_SECTION,
      useFactory: () => new BehaviorSubject<string>('unknown'),
    },
  ],
})
export class ActivitySectionDirective implements OnInit {
  @Input('coreActivitySection') section!: string;

  constructor(
    @Inject(CORE_USER_ACTIVITY_SECTION) private sectionSubject: BehaviorSubject<string>,
  ) {}

  ngOnInit(): void {
    this.sectionSubject.next(this.section);
  }
}
