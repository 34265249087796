import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { IKeyValue } from '../models';

@Injectable()
export class KeyValueStorageService {

  protected readonly collectionName = 'keyValue';

  public constructor(
    protected readonly db: NgxIndexedDBService,
  ) {}

  public set<T>(data: IKeyValue<T>): Observable<IKeyValue<T>> {
    return this.db.update(this.collectionName, data);
  }

  public get<T>(key: string): Observable<IKeyValue<T> | undefined> {
    return this.db.getByKey(this.collectionName, key);
  }

  public delete(key: string): Observable<unknown> {
    return this.db.deleteByKey(this.collectionName, key);
  }

  public bulkDelete(key: string[]): Observable<unknown> {
    return this.db.bulkDelete(this.collectionName, key);
  }

  public clear(): Observable<boolean> {
    return this.db.clear(this.collectionName);
  }

}
