import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, EMPTY, from } from 'rxjs';
import { map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RGBA } from 'color-blend/dist/types';
import { isBoolean, isNumber, isString } from '../utils';
import { CORE_BACKGROUND_PLAYER_AD_COLOR, CORE_FEATURE_TOGGLE } from '../../core.tokens';
import { WidgetUIConfiguration } from '../models';
import { FileCacheService } from './file-cache.service';
import { RestaurantTableService } from './restaurant-table.service';

@UntilDestroy()
@Injectable()
export class WidgetUiConfigService {

  private readonly files$ = new BehaviorSubject<Record<string, SafeUrl>>({});

  constructor(
    @Inject(CORE_FEATURE_TOGGLE) private readonly featureToggle: any,
    @Inject(CORE_BACKGROUND_PLAYER_AD_COLOR) private readonly backgroundAdPlayerColor: string | null,
    private readonly restaurantTable: RestaurantTableService,
    private readonly filesCache: FileCacheService,
    private readonly domSanitizer: DomSanitizer,
  ) {
    const urlCached$ = (urls: string[]) => from(urls).pipe(
      mergeMap((url) => this.filesCache.getFile(url)),
      map((file) => (
        {
          [file.url]: this.domSanitizer.bypassSecurityTrustUrl(
            file.objectUrl,
          ),
        }
      )),
      toArray(),
    );

    this.restaurantTable.table$.pipe(
      switchMap((table) => {
        const files = [
          table?.widgetUIConfig.logo,
          table?.widgetUIConfig.pages?.menu?.icon,
          table?.widgetUIConfig.pages?.serviceCenter?.icon,
          table?.widgetUIConfig.pages?.invoice?.icon,
        ].filter((url): url is string => {
          return isString(url) && !url.startsWith('data:');
        });

        return files.length > 0 ? urlCached$(files) : EMPTY;
      }),
      untilDestroyed(this),
    ).subscribe((results) => {
      this.files$.next(results.reduce((acc, file) => {
        return { ...acc, ...file };
      }, {}));
    });
  }

  get widgetUIConfig(): WidgetUIConfiguration | null {
    return this.restaurantTable.table$.getValue()?.widgetUIConfig ?? null;
  }

  get toolbarButtonsCount(): number {
    let count = 0;

    if (this.menuPageEnabled) {
      count++;
    }

    if (this.serviceCenterPageEnabled || this.callWaiterButtonEnabled) {
      count++;
    }

    if (this.invoicePageEnabled) {
      count++;
    }

    return count;
  }

  get logo(): SafeUrl | string {
    return this.getFileData(this.widgetUIConfig?.logo ?? '');
  }

  get idleDelay(): number {
    if (isNumber(this.featureToggle.idleDelay)) {
      return this.featureToggle.idleDelay ?? 30;
    }

    return this.widgetUIConfig?.idleDelay ?? 30;
  }

  get tabBarAutoHide(): boolean {
    if (isBoolean(this.featureToggle.tabBarAutoHide)) {
      return !!this.featureToggle.tabBarAutoHide;
    }

    return this.widgetUIConfig?.tabBarAutoHide ?? false;
  }

  get primaryColor(): string {
    return this.widgetUIConfig?.colors.primary || '#8363EC';
  }

  get primaryColorLight(): string {
    return this.adjustColorLightness(this.primaryColor, 30);
  }

  get primaryColorDark(): string {
    return this.adjustColorLightness(this.primaryColor, -30);
  }

  get secondaryColor(): string {
    return this.widgetUIConfig?.colors.secondary || '#000000';
  }

  get secondaryColorLight(): string {
    return this.adjustColorLightness(this.secondaryColor, 30);
  }

  get secondaryColorDark(): string {
    return this.adjustColorLightness(this.secondaryColor, -30);
  }

  get tertiaryColor(): string {
    return this.widgetUIConfig?.colors.tertiary || '#FCFCFC';
  }

  get tertiaryColorLight(): string {
    return this.adjustColorLightness(this.tertiaryColor, 30);
  }

  get tertiaryColorDark(): string {
    return this.adjustColorLightness(this.tertiaryColor, -30);
  }

  get adPlayerBackgroundColor(): string {
    if (this.backgroundAdPlayerColor) {
      return this.backgroundAdPlayerColor ?? '#000000';
    }

    return this.tertiaryColor;
  }

  get callWaiterButtonEnabled(): boolean {
    return (
      this.featureToggle.withCallWaiter
      && this.featureToggle.withTabBar
      && (
        this.widgetUIConfig?.callWaiterButtons.callWaiter.enabled || false
      )
    );
  }

  get callWaiterButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons.callWaiter.name || '';
  }

  get callWaiterButtonLightColor(): RGBA | null {
    if (this.featureToggle.ambientLight.callWaiter) {
      return this.featureToggle.ambientLight.callWaiter;
    }

    if (this.widgetUIConfig?.callWaiterButtons.callWaiter.lightsColor) {
      return this.hexToRgba(this.widgetUIConfig.callWaiterButtons.callWaiter.lightsColor);
    }

    return null;
  }

  get anotherRoundButtonEnabled(): boolean {
    return (
      this.featureToggle.withCallWaiterToRepeat
      && this.featureToggle.withTabBar
      && (
        this.widgetUIConfig?.callWaiterButtons.anotherRound.enabled || false
      )
    );
  }

  get anotherRoundButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons.anotherRound.name || '';
  }

  get anotherRoundButtonLightColor(): RGBA | null {
    if (this.featureToggle.ambientLight.callWaiter) {
      return this.featureToggle.ambientLight.callWaiterToRepeat;
    }

    if (this.widgetUIConfig?.callWaiterButtons.anotherRound.lightsColor) {
      return this.hexToRgba(this.widgetUIConfig.callWaiterButtons.anotherRound.lightsColor);
    }

    return null;
  }

  get requestBillButtonEnabled(): boolean {
    return (
      this.featureToggle.withCallWaiterToPay
      && this.featureToggle.withTabBar
      && (
        this.widgetUIConfig?.callWaiterButtons.requestBill.enabled || false
      )
    );
  }

  get requestBillButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons.requestBill.name || '';
  }

  get requestBillButtonLightColor(): RGBA | null {
    if (this.featureToggle.ambientLight.callWaiter) {
      return this.featureToggle.ambientLight.callWaiterToPay;
    }

    if (this.widgetUIConfig?.callWaiterButtons.requestBill.lightsColor) {
      return this.hexToRgba(this.widgetUIConfig.callWaiterButtons.requestBill.lightsColor);
    }

    return null;
  }

  get menuPageEnabled(): boolean {
    return this.widgetUIConfig?.pages.menu.enabled || false;
  }

  get menuPageName(): string {
    return this.widgetUIConfig?.pages.menu.name || '';
  }

  get menuPageIcon(): SafeUrl | string {
    return this.getFileData(this.widgetUIConfig?.pages.menu.icon || '');
  }

  get serviceCenterPageEnabled(): boolean {
    return (
      this.featureToggle.withServiceCentre
      && this.featureToggle.withTabBar
      && (
        this.widgetUIConfig?.pages.serviceCenter.enabled || false
      )
    );
  }

  get serviceCenterPageName(): string {
    return this.widgetUIConfig?.pages.serviceCenter.name || '';
  }

  get serviceCenterPageIcon(): SafeUrl | string {
    return this.getFileData(this.widgetUIConfig?.pages.serviceCenter.icon || '');
  }

  get invoicePageEnabled(): boolean {
    return (
      this.featureToggle.withInvoice
      && this.featureToggle.withTabBar
      && (
        this.widgetUIConfig?.pages.invoice.enabled || false
      )
    );
  }

  get invoicePageName(): string {
    return this.widgetUIConfig?.pages.invoice.name || '';
  }

  get invoicePageIcon(): SafeUrl | string {
    return this.getFileData(this.widgetUIConfig?.pages.invoice.icon || '');
  }

  public adjustColorLightness(color: string, amount: number): string {
    const colorHex = color.replace(/[^0-9a-f]/gi, '');
    const rgb = parseInt(colorHex, 16);
    const r = Math.min(
      255,
      Math.max(
        0,
        (
          (
            rgb >> 16
          ) & 0xff
        ) + amount,
      ),
    );
    const g = Math.min(
      255,
      Math.max(
        0,
        (
          (
            rgb >> 8
          ) & 0xff
        ) + amount,
      ),
    );
    const b = Math.min(
      255,
      Math.max(
        0,
        (
          rgb & 0xff
        ) + amount,
      ),
    );
    return '#' + (
      b | (
        g << 8
      ) | (
        r << 16
      )
    ).toString(16).padStart(6, '0');
  }

  private getFileData(url: string): SafeUrl | string {
    if (url.startsWith('data:')) {
      return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

    return this.files$.getValue()[url] || '';
  }

  private hexToRgba(hex: string): RGBA {
    return {
      r: parseInt(hex.slice(1, 3), 16),
      g: parseInt(hex.slice(3, 5), 16),
      b: parseInt(hex.slice(5, 7), 16),
      a: 1,
    };
  }

}
