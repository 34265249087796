import { Component, DestroyRef, Inject, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent, iif, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CORE_BACKGROUND_PLAYER_AD_URL, CORE_BACKGROUND_PLAYER_URL, CORE_DEBUG } from '../../../core.tokens';
import {
  DWallIntercom,
  NetworkService,
  RestaurantTableService,
  WatchdogService,
  WidgetUiConfigService,
} from '../../services';

@Component({
  selector: 'core-background-player',
  standalone: true,
  templateUrl: './background-player.component.html',
  imports: [
    NgIf,
  ],
  styleUrls: ['./background-player.component.scss'],
})
export class BackgroundPlayerComponent implements OnDestroy {

  public iframeUrl: SafeResourceUrl | null = null;
  private readonly logger = this.watchdog.tag('Background Player', 'lime');
  private channelMessagesSubscription: Subscription | null = null;
  private intercomMessagesSubscription: Subscription | null = null;

  constructor(
    @Inject(CORE_DEBUG) private readonly debug: boolean,
    @Inject(CORE_BACKGROUND_PLAYER_URL) private readonly backgroundPlayerUrl: string | null,
    @Inject(CORE_BACKGROUND_PLAYER_AD_URL) private readonly backgroundPlayerAdUrl: string | null,
    private readonly destroyRef: DestroyRef,
    private readonly sanitizer: DomSanitizer,
    private readonly watchdog: WatchdogService,
    private readonly connectionStatus: NetworkService,
    private readonly intercom: DWallIntercom,
    private readonly restaurantTable: RestaurantTableService,
    private readonly widgetUiConfig: WidgetUiConfigService,
  ) {
    const setIframeUrl = (url: string | null): void => {
      this.iframeUrl = url
        ? this.sanitizer.bypassSecurityTrustResourceUrl(url)
        : null;
    };

    const backgroundPlayerUrl$ = this.restaurantTable.table$.pipe(
      map((table) => {
        const config: {
          type: 'order' | 'time',
          every: number,
          bgColor: string,
          debug: boolean,
          providers: {
            id: string,
            [key: string]: unknown
          }[]
        } = {
          type: 'order',
          every: table?.adRunEvery ?? 3,
          bgColor: this.widgetUiConfig.adPlayerBackgroundColor,
          debug: this.debug,
          providers: [],
        };

        if (table?.integrations?.length) {
          table?.integrations.map(integration => {

            if (integration.provider === 'VAST') {
              config.providers.push({
                id: 'VAST',
                tagUrl: integration.credentials.vastTagUrl,
              });
            }

            if (integration.provider === 'vistarMedia') {
              config.providers.push({
                id: 'VistarMedia',
                apiKey: integration.credentials.apiKey,
                networkId: integration.credentials.networkId,
                venueId: integration.credentials.venueId,
              });
            }
          });
        }
        return config;
      }),
      map((config) => {
        if (config.providers.length > 0) {
          return btoa(unescape(encodeURIComponent(JSON.stringify(config))));
        }

        return null;
      }),
      map((config) => {
        if (this.backgroundPlayerAdUrl && config) {
          const url = new URL(this.backgroundPlayerAdUrl);
          url.searchParams.set('config', config);
          return url.toString();
        }

        return this.backgroundPlayerUrl;
      }),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    );

    this.connectionStatus.status$.pipe(
      switchMap((status) => iif(
        () => status,
        backgroundPlayerUrl$,
        of(null),
      )),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((url) => {
      setIframeUrl(url);
    });

    fromEvent(window, 'unload').pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.intercom.call('playlist.resume');
    });

    this.destroyRef.onDestroy(() => {
      this.intercom.call('playlist.resume');

      this.channelMessagesSubscription?.unsubscribe();
      this.intercomMessagesSubscription?.unsubscribe();
    });
  }

  public onLoadData(event: Event): void {
    const iframe = event.target as HTMLIFrameElement;
    const channel = new MessageChannel();

    this.channelMessagesSubscription?.unsubscribe();
    this.intercomMessagesSubscription?.unsubscribe();

    iframe.contentWindow?.postMessage(
      'dwall-handshake', '*', [channel.port2],
    );

    this.channelMessagesSubscription = fromEvent<MessageEvent>(
      channel.port1, 'message',
    ).subscribe((messageEvent) => {
      const message = JSON.parse(messageEvent.data);

      this.logger.debug('Received', message);

      this.intercom.call(message.method, message.data);
    });

    this.intercomMessagesSubscription = this.intercom.messages$.subscribe((message) => {
      this.logger.debug('Sent', message);

      channel.port1.postMessage(JSON.stringify(message));
    });

    channel.port1.start();
  }

  public onError(event: Event): void {
    console.error('Could not load background player', event);
    this.iframeUrl = null;
  }

  public ngOnDestroy(): void {
    this.intercom.call('playlist.resume');
  }

}
