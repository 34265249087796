import { Component, Input } from '@angular/core';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  standalone: true,
  selector: 'app-qr-view',
  templateUrl: './qr-view.component.html',
  imports: [
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  styleUrls: ['./qr-view.component.scss'],
})
export class QrViewComponent {

  @Input() title = '';
  @Input() imgSrc = '';
  @Input() description: string | undefined;
  @Input() description2: string | undefined;
  @Input() qrSrc: string | undefined;
  @Input() moreData: unknown | undefined;

}
