import {
  animationFrameScheduler,
  asapScheduler,
  asyncScheduler,
  defer,
  EMPTY,
  from,
  MonoTypeOperatorFunction,
  Observable,
  ObservableInput,
  observeOn,
  queueScheduler,
  scheduled,
  subscribeOn,
} from 'rxjs';

export const scheduledQueue = <T>(input: ObservableInput<T>): Observable<T> => {
  return scheduled(input, queueScheduler);
};

export const scheduledAsap = <T>(input: ObservableInput<T>): Observable<T> => {
  return scheduled(input, asapScheduler);
};

export const scheduledAsync = <T>(input: ObservableInput<T>): Observable<T> => {
  return scheduled(input, asyncScheduler);
};

export const scheduledAnimationFrame = <T>(input: ObservableInput<T>): Observable<T> => {
  return scheduled(input, animationFrameScheduler);
};

export const observeOnQueue = <T>(): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => source$.pipe(
    observeOn<T>(queueScheduler),
  );
};

export const observeOnAsap = <T>(): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => source$.pipe(
    observeOn<T>(asapScheduler),
  );
};

export const observeOnAsync = <T>(): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => source$.pipe(
    observeOn<T>(asyncScheduler),
  );
};

export const observeOnAnimationFrame = <T>(): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => source$.pipe(
    observeOn<T>(animationFrameScheduler),
  );
};

export const subscribeOnQueue = <T>(): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => source$.pipe(
    subscribeOn<T>(queueScheduler),
  );
};

export const subscribeOnAsap = <T>(): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => source$.pipe(
    subscribeOn<T>(asapScheduler),
  );
};

export const subscribeOnAsync = <T>(): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => source$.pipe(
    subscribeOn<T>(asyncScheduler),
  );
};

export const subscribeOnAnimationFrame = <T>(): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => source$.pipe(
    subscribeOn<T>(animationFrameScheduler),
  );
};

export const tapSubscribed = <T>(callback: () => void): MonoTypeOperatorFunction<T> => {
  return (source$: Observable<T>) => defer(() => {
    callback();
    return source$;
  });
};

export const fromArray = <T>(items: T[]) => items.length === 0 ? EMPTY : from(items);
