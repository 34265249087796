<core-debug-ui-items>
  <core-debug-ui-field label="Timezone">{{ deviceTimezone }}</core-debug-ui-field>
  <core-debug-ui-field label="Locale">{{ deviceLocale | uppercase }}</core-debug-ui-field>
  <core-debug-ui-field label="Numbering System">{{ deviceNumberingSystem | titlecase }}</core-debug-ui-field>
</core-debug-ui-items>

<core-debug-ui-items label="Screen">
  <core-debug-ui-field label="Viewport Size">
    @if (viewport$ | async; as viewport) {
      {{ viewport.width }} x {{ viewport.height }} px
    } @else {
      N/A
    }
  </core-debug-ui-field>
  <core-debug-ui-field label="Screen Size">{{ screen.width }} x {{ screen.height }} px</core-debug-ui-field>
  <core-debug-ui-field label="Pixel Ratio">{{ pixelRatio }}</core-debug-ui-field>
  <core-debug-ui-field label="Color Depth">{{ screen.colorDepth }}</core-debug-ui-field>
  <core-debug-ui-field label="Orientation">{{ screen.orientation.type }}</core-debug-ui-field>
</core-debug-ui-items>
