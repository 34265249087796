import { Inject, Injectable } from '@angular/core';
import { CORE_DEBUG } from '../../core.tokens';

const styles = {
  reset: `\x1b[0m`,
  bright: '\x1b[1m',
  dim: '\x1b[2m',
  underscore: '\x1b[4m',
  blink: '\x1b[5m',
  reverse: '\x1b[7m',
  hidden: '\x1b[8m',
  color: {
    black: '\x1b[30m',
    red: `\x1b[31m`,
    green: '\x1b[32m',
    yellow: '\x1b[33m',
    blue: '\x1b[34m',
    magenta: '\x1b[35m',
    cyan: '\x1b[36m',
    white: '\x1b[37m',
    crimson: '\x1b[38m',
    lime: '\x1b[92m',
  },
  bg: {
    black: '\x1b[40m',
    red: '\x1b[41m',
    green: '\x1b[42m',
    yellow: '\x1b[43m',
    blue: '\x1b[44m',
    magenta: '\x1b[45m',
    cyan: '\x1b[46m',
    white: '\x1b[47m',
    crimson: '\x1b[48m',
    lime: '\x1b[102m',
  },
};

type Color = keyof typeof styles.color;
type Background = keyof typeof styles.bg;

const styled = (color: Color, background?: Background): string => {
  let style = styles.color[color];

  if (background) {
    style += styles.bg[background];
  }

  return `${ style }%s${ styles.reset }`;
};

export interface Logger {
  log(...args: unknown[]): void;

  info(...args: unknown[]): void;

  warn(...args: unknown[]): void;

  error(...args: unknown[]): void;

  debug(...args: unknown[]): void;
}

@Injectable()
export class WatchdogService implements Logger {

  public readonly log: (...args: unknown[]) => void = console.log.bind(console.log);

  // tslint:disable-next-line:no-console
  public readonly info: (...args: unknown[]) => void = console.info.bind(console.info);

  public readonly warn: (...args: unknown[]) => void = console.warn.bind(console.warn);

  public readonly error: (...args: unknown[]) => void = console.error.bind(console.error);

  // tslint:disable-next-line:no-console
  public readonly debug: (...args: unknown[]) => void = this.isDebug ? console.debug.bind(console.debug) : () => {};

  constructor(
    @Inject(CORE_DEBUG) private readonly isDebug: boolean,
  ) {}

  public tag(tag: string, color: Color, background?: Background): Logger {
    return {
      log: this.log.bind(this, styled(color, background), `[${ tag }]`),
      info: this.info.bind(this, styled(color, background), `[${ tag }]`),
      warn: this.warn.bind(this, styled(color, background), `[${ tag }]`),
      error: this.error.bind(this, styled(color, background), `[${ tag }]`),
      debug: this.debug.bind(this, styled(color, background), `[${ tag }]`),
    };
  }

}
