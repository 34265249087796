import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FileCacheModel } from '../models';
import { FileDownloaderService } from './file-downloader.service';
import { FileCacheStorageService } from './file-cache.storage.service';

@UntilDestroy()
@Injectable()
export class FileCacheService {

  public constructor(
    private readonly downloader: FileDownloaderService,
    private readonly storage: FileCacheStorageService,
  ) {}

  public getFiles(urls: string[]): Observable<FileCacheModel[]> {
    return forkJoin(
      urls.map((url) => this.getFile(url)),
    ).pipe(
      map((files) => {
        return files.sort((a, b) => urls.indexOf(a.url) - urls.indexOf(b.url));
      }),
    );
  }

  public getFile(url: string): Observable<FileCacheModel> {
    const fileDownloader$ = this.downloader.downloadAsBlob(url).pipe(
      switchMap((blob) => this.storage.add(url, blob)),
    );

    return this.storage.get(url).pipe(
      switchMap((file) => {
        if (file instanceof FileCacheModel) {
          return of(file);
        }

        return fileDownloader$;
      }),
    );
  }

  public delete(url: string): Observable<unknown> {
    return this.storage.delete(url);
  }

  public bulkDelete(urls: string[]): Observable<unknown> {
    return this.storage.bulkDelete(urls);
  }

  public clearCache(): Observable<boolean> {
    return this.storage.clear();
  }

}
