import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'core-debug-ui-card',
  standalone: true,
  templateUrl: './debug-card.component.html',
  styleUrls: ['./debug-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugCardComponent {

  public label = input.required<string>();

}
