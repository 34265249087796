<core-debug-ui-items>
  <core-debug-ui-field label="Count">
    {{ members().length }}
  </core-debug-ui-field>
  <core-debug-ui-field label="Leader">
    @if (leader()) {
      <span style="color: #2df702;">Yes</span>
    } @else {
      <span style="color: #f12f2f;">No</span>
    }
  </core-debug-ui-field>
</core-debug-ui-items>

<core-debug-ui-items label="Screens">
  @for (item of members(); track item.id) {
    <core-debug-ui-field label="{{ item.id }}" class="gap-2">
      @if (item.self) {
        <span style="color: #f2f702;">Self</span>
      }

      @if (item.leader) {
        <span style="color: #2df702; margin-left: 4px;">Leader</span>
      }
    </core-debug-ui-field>
  }
</core-debug-ui-items>
