import moment from 'moment-timezone';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { interval, merge, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AsyncPipe, DatePipe } from '@angular/common';
import {
  CORE_APP_ENV,
  CORE_APP_NAME,
  CORE_APP_VERSION,
  CORE_STARTUP_DATE,
  CORE_STARTUP_ID,
} from '../../../../../core.tokens';
import { DebugFieldComponent, DebugItemsComponent, DebugSizeComponent } from '../../ui';
import {
  InteractionService,
  NetworkService,
  RestaurantTableService,
  UpdateService,
  WebsocketService,
} from '../../../../services';

@Component({
  selector: 'core-debug-app',
  standalone: true,
  templateUrl: './debug-app.component.html',
  styleUrls: ['./debug-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugItemsComponent,
    DebugFieldComponent,
    DebugSizeComponent,
    AsyncPipe,
    DatePipe,
  ],
})
export class DebugAppComponent {

  public readonly date$ = merge(
    of(0),
    interval(5_000),
  ).pipe(
    map(() => moment().format('YYYY-MM-DD HH:mm')),
    distinctUntilChanged(),
  );

  public readonly uptime$ = merge(
    of(0),
    interval(5_000),
  ).pipe(
    map(() => moment.duration(moment().diff(moment(this.appStartupDate))).humanize()),
    distinctUntilChanged(),
  );

  public readonly callWaiter$ = this.restaurantTable.callWaiter$;
  public readonly callWaiterToPay$ = this.restaurantTable.callWaiterToPay$;
  public readonly callWaiterToRepeat$ = this.restaurantTable.callWaiterToRepeat$;

  public readonly updateEnabled = this.update.enabled;
  public readonly updateStatus$ = this.update.status$;
  public readonly updateCheckStrategyTitle = this.update.updateCheckStrategyTitle;
  public readonly updateActivateStrategyTitle = this.update.updateActivateStrategyTitle;

  public readonly interactionStatus$ = this.interaction.idle$;
  public readonly interactionLastInteractionAt$ = this.interaction.lastInteractionAt$;
  public readonly interactionLastStartInteractionAt$ = this.interaction.lastStartInteractionAt$;
  public readonly interactionLastStartIdleAt$ = this.interaction.lastStartIdleAt$;
  public readonly interactionIdleDelay = this.interaction.idleDelay;

  public readonly networkStatus$ = this.network.status$;
  public readonly networkConnectionCount$ = this.network.connectionCount$;
  public readonly networkLastConnectionAt$ = this.network.lastConnectionAt$;
  public readonly networkLastDisconnectionAt$ = this.network.lastDisconnectionAt$;

  public readonly websocketStatus$ = this.websocket.status$;
  public readonly websocketConnectionCount$ = this.websocket.connectionCount$;
  public readonly websocketLastConnectionAt$ = this.websocket.lastConnectionAt$;
  public readonly websocketLastDisconnectionAt$ = this.websocket.lastDisconnectionAt$;

  constructor(
    @Inject(CORE_STARTUP_ID) public readonly appStartupId: string,
    @Inject(CORE_STARTUP_DATE) public readonly appStartupDate: string,
    @Inject(CORE_APP_NAME) public readonly appName: string,
    @Inject(CORE_APP_ENV) public readonly appEnv: string,
    @Inject(CORE_APP_VERSION) public readonly appVersion: string,
    private readonly update: UpdateService,
    private readonly interaction: InteractionService,
    private readonly network: NetworkService,
    private readonly websocket: WebsocketService,
    private readonly restaurantTable: RestaurantTableService,
  ) {}

}
