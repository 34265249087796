import { DestroyRef, Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ScreenTrackingService {

  private readonly screenSubject = new BehaviorSubject<string>('unknown');

  public readonly screen$ = this.screenSubject.asObservable();

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof RouterEvent),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      }),
      mergeMap(route => route.data),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((data) => {
      this.screenSubject.next(data['screen'] ?? 'unknown');
    });
  }

}

