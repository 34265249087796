<div class="container" coreActivitySection="App">
  <div class="container__image">
    <img src="/images/appImg.jpg" alt="">
  </div>
  <div class="container__description">
    <span>Get closer to us</span>
  </div>
  <div class="container__links">
    <img (click)="showQRCode('/images/appStoreQr.png')"
         src="/images/appStore.svg" alt="appStore"
         coreUserActivityClickTracking="ShowAppStoreQR"
    >
    <img (click)="showQRCode('/images/googleQr.png')"
         src="/images/google.svg" alt="googlePlay"
         coreUserActivityClickTracking="ShowGooglePlayQR"
    >
  </div>

  <app-qr-view
    *ngIf="isQRVisible"
    (click)="isQRVisible = !isQRVisible"
    [qrSrc]="qrSrc"
  ></app-qr-view>
</div>
