import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'core-debug-ui-stack',
  standalone: true,
  templateUrl: './debug-stack.component.html',
  styleUrls: ['./debug-stack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugStackComponent {}
