import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { importProvidersFrom } from '@angular/core';

export function migrationFactory() {

  return {
    3: (db: IDBDatabase) => {
      db.deleteObjectStore('happyHours')
    }
  };
}

const coreDatabase: DBConfig = {
  name: 'excuse-me.offline' as const,
  isDefault: true as const,
  version: 3,
  objectStoresMeta: [
    {
      store: 'keyValue',
      storeConfig: { keyPath: 'key', autoIncrement: false },
      storeSchema: [
        { name: 'key', keypath: 'key', options: { unique: true } },
        { name: 'value', keypath: 'value', options: { unique: false } },
      ],
    },
    {
      store: 'fileCache',
      storeConfig: { keyPath: 'url', autoIncrement: false },
      storeSchema: [
        { name: 'url', keypath: 'url', options: { unique: true } },
        { name: 'blob', keypath: 'blob', options: { unique: false } },
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
      ],
    },
    {
      store: 'happyHoursPeriod',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'startDay', keypath: 'startDay', options: { unique: false } },
        { name: 'startTime', keypath: 'startTime', options: { unique: false } },
        { name: 'endDay', keypath: 'endDay', options: { unique: false } },
        { name: 'endTime', keypath: 'endTime', options: { unique: false } },
      ],
    },
    {
      store: 'specials',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'ownerType', keypath: 'ownerType', options: { unique: false } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'scheduleStart', keypath: 'scheduleStart', options: { unique: false } },
        { name: 'scheduleEnd', keypath: 'scheduleEnd', options: { unique: false } },
        { name: 'sequence', keypath: 'sequence', options: { unique: false } },
        { name: 'content', keypath: 'content', options: { unique: false } },
        { name: 'updatedAt', keypath: 'updatedAt', options: { unique: false } },
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
      ],
    },
    {
      store: 'userActivity',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'app', keypath: 'app', options: { unique: false } },
        { name: 'appVersion', keypath: 'appVersion', options: { unique: false } },
        { name: 'environment', keypath: 'environment', options: { unique: false } },
        { name: 'action', keypath: 'action', options: { unique: false } },
        { name: 'target', keypath: 'target', options: { unique: false } },
        { name: 'screen', keypath: 'screen', options: { unique: false } },
        { name: 'section', keypath: 'section', options: { unique: false } },
        { name: 'clientX', keypath: 'clientX', options: { unique: false } },
        { name: 'clientY', keypath: 'clientY', options: { unique: false } },
        { name: 'capturedAt', keypath: 'capturedAt', options: { unique: false } },
        { name: 'sentAt', keypath: 'sentAt', options: { unique: false } },
      ],
    },
  ],

  migrationFactory
};

export const provideCoreDatabase = (configs: DBConfig | DBConfig[] = []) => importProvidersFrom(NgxIndexedDBModule.forRoot(
  ...[
    coreDatabase,
    ...(
      Array.isArray(configs) ? configs : [configs]
    ),
  ]));
