<div class="back-navigation" coreActivitySection="QRView">
  <button class="back" coreUserActivityClickTracking="Back">
    ◁ Back to category
  </button>
</div>

<div class="box">
  <div class="container__image">
    <img src="/images/appImg.jpg" alt="">
  </div>
  <ng-container>
    <div class="text">
      <div class="title">Scan to download</div>
    </div>
    <img class="qrImg" [src]="qrSrc" alt="">
    <img class="image" [src]="imgSrc" alt="">
  </ng-container>
</div>
