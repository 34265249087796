import { Directive, HostListener, Inject, Input, OnInit } from '@angular/core';
import { ClickTrackingService, CORE_USER_ACTIVITY_SECTION, ScreenTrackingService } from '../services';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Directive({
  selector: '[coreUserActivityClickTracking]',
  standalone: true,
})
export class UserActivityClickTrackingDirective implements OnInit {
  @Input('coreUserActivityClickTracking') target!: string;
  private section = '';
  private screen = '';

  constructor(
    @Inject(CORE_USER_ACTIVITY_SECTION) private sectionSubject: BehaviorSubject<string>,
    private readonly screenTracking: ScreenTrackingService,
    private readonly clickTracking: ClickTrackingService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.sectionSubject,
      this.screenTracking.screen$,
    ]).subscribe(([section, screen]) => {
      this.section = section;
      this.screen = screen;
    });
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent): void {
    event.preventDefault();
    this.clickTracking.trackClick(event, this.screen, this.section, this.target);
  }
}
