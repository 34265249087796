import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'core-debug-ui-field',
  standalone: true,
  templateUrl: './debug-field.component.html',
  styleUrls: ['./debug-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugFieldComponent {

  public label = input.required<string>();

}
