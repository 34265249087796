import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isUndefined } from '../utils';
import { FileCacheModel, IFileCache } from '../models';
import { WatchdogService } from './watchdog.service';

@Injectable()
export class FileCacheStorageService {

  private readonly collectionName = 'fileCache';

  private readonly logger = this.watchdog.tag('File Cache Storage', 'blue');

  public constructor(
    private readonly db: NgxIndexedDBService,
    private readonly watchdog: WatchdogService,
  ) {}

  public add(fileUrl: string, fileBlob: Blob): Observable<FileCacheModel> {
    return this.db.update<IFileCache>(this.collectionName, {
      url: fileUrl,
      blob: fileBlob,
      createdAt: Date.now(),
    }).pipe(
      map((entry) => new FileCacheModel(entry)),
      tap({
        next: () => this.logger.debug('File added to cache', fileUrl),
        error: (error) => this.logger.error('Failed to add file to cache', fileUrl, error),
      }),
    );
  }

  public get(fileUrl: string): Observable<FileCacheModel | undefined> {
    return this.db.getByKey<IFileCache | undefined>(this.collectionName, fileUrl).pipe(
      map((entry) => {
        if (isUndefined(entry)) {
          return undefined;
        }

        return new FileCacheModel(entry);
      }),
      tap({
        next: (file) => {
          if (file) {
            this.logger.debug('File loaded from cache', file);
          }
          else {
            this.logger.warn('File not found in cache', fileUrl);
          }
        },
        error: (error) => this.logger.error('Failed to load file from cache', fileUrl, error),
      }),
    );
  }

  public delete(fileUrl: string): Observable<unknown> {
    return this.db.deleteByKey(this.collectionName, fileUrl).pipe(
      tap({
        next: () => this.logger.debug('File deleted from cache', fileUrl),
        error: (error) => this.logger.error('Failed to delete file from cache', fileUrl, error),
      }),
    );
  }

  public bulkDelete(fileUrls: string[]): Observable<unknown> {
    return this.db.bulkDelete(this.collectionName, fileUrls).pipe(
      tap({
        next: () => this.logger.debug('Files deleted from cache', fileUrls),
        error: (error) => this.logger.error('Failed to delete files from cache', fileUrls, error),
      }),
    );
  }

  public clear(): Observable<boolean> {
    return this.db.clear(this.collectionName).pipe(
      tap({
        next: () => this.logger.debug('Cache cleared'),
        error: (error) => this.logger.error('Failed to clear cache', error),
      }),
    );
  }

}
