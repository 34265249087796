import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export type CoreUpdateCheckStrategy =
  | 'manual'
  | 'every-minute'
  | 'every-5-minutes'
  | 'every-10-minutes'
  | 'every-30-minutes'
  | 'every-hour'
  | 'every-2-hours'
  | 'every-6-hours'
  | 'every-day'
  | number
  | (() => Observable<unknown>);

export type CoreUpdateActivateStrategy =
  | 'manual'
  | 'always-restart'
  | 'restart-when-idle'
  | 'restart-when-online'
  | 'restart-when-offline'
  | 'restart-when-idle-and-online'
  | 'restart-when-idle-and-offline'
  | { 'restart-delay': number }
  | { 'restart-when': () => Observable<boolean> };

export interface CoreAmbientLightColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface CoreFeatureToggle {
  idleClickGoTo: unknown[];
  withTabBar: boolean;
  withCallWaiter: boolean;
  withCallWaiterToPay: boolean;
  withCallWaiterToRepeat: boolean;
  withCallWaiterOnOffline: boolean;
  withInvoice: boolean;
  withServiceCentre: boolean;
  tabBarAutoHide: undefined | boolean;
  idleDelay: undefined | number;
  ambientLight: {
    callWaiter: CoreAmbientLightColor | null;
    callWaiterToPay: CoreAmbientLightColor | null;
    callWaiterToRepeat: CoreAmbientLightColor | null;
  };
}

export const CORE_DEVICE_TIMEZONE = new InjectionToken<string>('CORE_DEVICE_TIMEZONE');

export const CORE_DEVICE_LOCALE = new InjectionToken<string>('CORE_DEVICE_LOCALE');

export const CORE_DEVICE_NUMBERING_SYSTEM = new InjectionToken<string>('CORE_DEVICE_NUMBERING_SYSTEM');

export const CORE_STARTUP_ID = new InjectionToken<string>('CORE_STARTUP_ID');

export const CORE_STARTUP_DATE = new InjectionToken<Date>('CORE_START_DATE');

export const CORE_APP_NAME = new InjectionToken<string>('CORE_APP_NAME');

export const CORE_APP_VERSION = new InjectionToken<string>('CORE_APP_VERSION');

export const CORE_APP_ENV = new InjectionToken<string>('CORE_APP_ENV');

export const CORE_DEBUG = new InjectionToken<boolean>('CORE_DEBUG');

export const CORE_UPDATE_CHECK_STRATEGY = new InjectionToken<CoreUpdateCheckStrategy>(
  'CORE_UPDATE_CHECK_STRATEGY',
);

export const CORE_UPDATE_ACTIVATE_STRATEGY = new InjectionToken<CoreUpdateActivateStrategy>(
  'CORE_UPDATE_ACTIVATE_STRATEGY',
);

export const CORE_AUTH_PAGE_PATH = new InjectionToken<string>('CORE_AUTH_PAGE_PATH');

export const CORE_MAIN_PAGE_PATH = new InjectionToken<string>('CORE_MAIN_PAGE_PATH');

export const CORE_API_BASE_URL = new InjectionToken<string>('CORE_API_BASE_URL');

export const CORE_WEBSOCKET_BASE_URL = new InjectionToken<string>('CORE_WEBSOCKET_BASE_URL');

export const CORE_BACKGROUND_PLAYER_URL = new InjectionToken<string | null>('CORE_BACKGROUND_PLAYER_URL');

export const CORE_BACKGROUND_PLAYER_AD_URL = new InjectionToken<string | null>('CORE_BACKGROUND_PLAYER_AD_URL');

export const CORE_BACKGROUND_PLAYER_AD_COLOR = new InjectionToken<string | null>('CORE_BACKGROUND_PLAYER_AD_COLOR');

export const CORE_FEATURE_TOGGLE = new InjectionToken<CoreFeatureToggle>('CORE_FEATURE_TOGGLE');

export const provideCoreStartupId = (id: string) => (
  {
    provide: CORE_STARTUP_ID,
    useValue: id,
  }
);

export const provideCoreDeviceTimezone = (timezone: string) => (
  {
    provide: CORE_DEVICE_TIMEZONE,
    useValue: timezone,
  }
);

export const provideCoreDeviceLocale = (locale: string) => (
  {
    provide: CORE_DEVICE_LOCALE,
    useValue: locale,
  }
);

export const provideCoreDeviceNumberingSystem = (numberingSystem: string) => (
  {
    provide: CORE_DEVICE_NUMBERING_SYSTEM,
    useValue: numberingSystem,
  }
);

export const provideCoreStartupDate = (date: Date) => (
  {
    provide: CORE_STARTUP_DATE,
    useValue: date,
  }
);

export const provideCoreAppName = (name: string) => (
  {
    provide: CORE_APP_NAME,
    useValue: name,
  }
);

export const provideCoreAppVersion = (version: string) => (
  {
    provide: CORE_APP_VERSION,
    useValue: version,
  }
);

export const provideCoreAppEnv = (env: string) => (
  {
    provide: CORE_APP_ENV,
    useValue: env,
  }
);

export const provideCoreDebug = (debug: boolean) => (
  {
    provide: CORE_DEBUG,
    useValue: debug,
  }
);

export const provideCoreUpdateCheckStrategy = (check: CoreUpdateCheckStrategy = 'manual') => (
  {
    provide: CORE_UPDATE_CHECK_STRATEGY,
    useValue: check,
  }
);

export const provideCoreUpdateActivateStrategy = (reload: CoreUpdateActivateStrategy = 'manual') => (
  {
    provide: CORE_UPDATE_ACTIVATE_STRATEGY,
    useValue: reload,
  }
);

export const provideCoreAuthPagePath = (path: string) => (
  {
    provide: CORE_AUTH_PAGE_PATH,
    useValue: path,
  }
);

export const provideCoreMainPagePath = (path: string) => (
  {
    provide: CORE_MAIN_PAGE_PATH,
    useValue: path,
  }
);

export const provideCoreApiBaseUrl = (baseUrl: string) => (
  {
    provide: CORE_API_BASE_URL,
    useValue: baseUrl,
  }
);

export const provideCoreWebsocketBaseUrl = (baseUrl: string) => (
  {
    provide: CORE_WEBSOCKET_BASE_URL,
    useValue: baseUrl,
  }
);

export const provideCoreBackgroundPlayerUrl = (url: string | null) => (
  {
    provide: CORE_BACKGROUND_PLAYER_URL,
    useValue: url,
  }
);

export const provideCoreBackgroundPlayerAdUrl = (url: string | null) => (
  {
    provide: CORE_BACKGROUND_PLAYER_AD_URL,
    useValue: url,
  }
);

export const provideCoreBackgroundPlayerAdColor = (color: string | null) => (
  {
    provide: CORE_BACKGROUND_PLAYER_AD_COLOR,
    useValue: color,
  }
);

export const provideCoreFeatureToggle = (featureToggle: CoreFeatureToggle) => (
  {
    provide: CORE_FEATURE_TOGGLE,
    useValue: featureToggle,
  }
);
