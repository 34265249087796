import { importProvidersFrom } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export const provideCoreTranslation = () => importProvidersFrom(
  TranslateModule.forRoot({
    defaultLanguage: 'en-CA',
    useDefaultLang: true,
    isolate: true,
    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/locale/', '.json'),
      deps: [HttpClient],
    },
  }),
);
