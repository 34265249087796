import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, merge, of, Subscription, timer } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, share, switchMap } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { AuthService, NetworkService, WatchdogService } from '../../services';
import { CORE_WEBSOCKET_BASE_URL } from '../../../core.tokens';
import { toSignal } from '@angular/core/rxjs-interop';

@UntilDestroy()
@Component({
  selector: 'auth-page',
  standalone: true,
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
  imports: [
    TranslateModule,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPageComponent implements OnInit {

  public readonly online = toSignal(this.network.status$);
  public readonly code = signal<string | null>(null);
  private readonly logger = this.watchdog.tag('WebSocket', 'magenta');
  private readonly webSocket$ = webSocket({
    url: `${ this.websocketBaseUrl }/ws/auth`,
    openObserver: {
      next: () => this.logger.log('Open'),
    },
    closingObserver: {
      next: () => {
        this.logger.log('Closing');
        this.clearWidgetCode();
      },
    },
    closeObserver: {
      next: () => {
        this.logger.log('Close');
        this.clearWidgetCode();
      },
    },
  });

  constructor(
    @Inject(CORE_WEBSOCKET_BASE_URL) private readonly websocketBaseUrl: string,
    private readonly translate: TranslateService,
    private readonly watchdog: WatchdogService,
    private readonly network: NetworkService,
    private readonly auth: AuthService,
  ) {}

  get currentLanguage(): string {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  ngOnInit(): void {
    this.initWorkerWebSocket();
  }

  public handlerChangeLanguage(event: Event): void {
    if (event.target instanceof HTMLSelectElement) {
      this.translate.use(event.target.value);
    }
  }

  private initWorkerWebSocket(): void {
    let wsSub: Subscription | null = null;
    let timeoutSub: Subscription | null = null;
    let heartbeatSub: Subscription | null = null;

    const unsubscribeAll = () => {
      wsSub?.unsubscribe();
      wsSub = null;
      timeoutSub?.unsubscribe();
      timeoutSub = null;
      heartbeatSub?.unsubscribe();
      heartbeatSub = null;
    };

    const initWebSocket = () => {
      unsubscribeAll();

      const share$ = this.webSocket$.pipe(
        filter((response: any) => !!(
          response?.type
        )),
        catchError((error) => {
          this.logger.error(error);
          return EMPTY;
        }),
        share(),
      );

      timeoutSub = merge(of('init'), share$).pipe(
        switchMap(() => of('timeout').pipe(delay(10000))),
        untilDestroyed(this),
      ).subscribe((msg) => {
        this.logger.log(msg);

        initWebSocket();
      });

      heartbeatSub = timer(1000, 5000).pipe(
        untilDestroyed(this),
      ).subscribe(() => {
        this.logger.debug('Heartbeat send', new Date().toISOString());
        this.webSocket$.next({ type: 'heartbeat' });
      });

      wsSub = share$.pipe(
        untilDestroyed(this),
      ).subscribe(
        (response: any) => this.wsOnMessage(response),
        (error) => {
          this.logger.error(error);

          initWebSocket();
        },
        () => this.logger.log('Complete'),
      );
    };

    this.network.status$.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe((status) => {
      if (status) {
        initWebSocket();
      }
      else {
        unsubscribeAll();
      }
    });
  }

  private wsOnMessage(response: any): void {
    switch (response.type) {
      case 'widgetCode':
        this.code.set(response.data.widgetCode);
        this.logger.debug('Widget code received', response.data.widgetCode);
        break;

      case 'jwt':
        this.webSocket$.complete();
        this.auth.login(response.data.JWT);
        this.logger.debug('Token received');
        break;

      case 'heartbeat':
        this.logger.debug('Heartbeat received', response.data?.time);
        break;

      default:
        this.logger.warn('Unknown message', response);
        break;
    }
  }

  private clearWidgetCode(): void {
    this.code.set(null);
  }

}
