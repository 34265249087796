import {
  ActivityLogsStorageService,
  AuthService,
  ClickTrackingService,
  DeviceWorkScheduleService,
  DWallIntercom,
  FileCacheService,
  FileCacheStorageService,
  FileDownloaderService,
  HappyHoursService,
  HappyHoursStorageService,
  InteractionService,
  InvoiceQrcodeImageService,
  KeyValueStorageService,
  MenusService,
  NetworkService,
  RestaurantTableService,
  RssService,
  ScreenTrackingService,
  SpecialsService,
  SpecialsStorageService,
  SwarmService,
  UpdateService,
  WatchdogService,
  WebsocketService,
  WidgetUiConfigService,
} from './lib/services';

export const provideCoreServices = () => [
  UpdateService,
  NetworkService,
  InteractionService,
  AuthService,
  SwarmService,
  DWallIntercom,
  FileCacheService,
  FileCacheStorageService,
  FileDownloaderService,
  HappyHoursService,
  HappyHoursStorageService,
  InvoiceQrcodeImageService,
  KeyValueStorageService,
  WatchdogService,
  MenusService,
  RestaurantTableService,
  RssService,
  SpecialsService,
  SpecialsStorageService,
  WebsocketService,
  WidgetUiConfigService,
  DeviceWorkScheduleService,
  ActivityLogsStorageService,
  ClickTrackingService,
  ScreenTrackingService,
];
