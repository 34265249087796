import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { from, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isUndefined } from '../utils';
import { ISpecial, ISpecialRaw } from '../models';
import { FileCacheService } from './file-cache.service';
import { WatchdogService } from './watchdog.service';

const collectionName = 'specials';

@UntilDestroy()
@Injectable()
export class SpecialsStorageService {

  private readonly logger = this.watchdog.tag('Specials Storage', 'blue');

  constructor(
    private readonly db: NgxIndexedDBService,
    private readonly watchdog: WatchdogService,
    private readonly auth: AuthService,
    private readonly filesCache: FileCacheService,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.auth.logouted$.pipe(
      switchMap(() => this.clear()),
      tap(() => this.logger.debug('Specials storage cleared on logout')),
      untilDestroyed(this),
    ).subscribe();
  }

  public getAllSpecials(): Observable<ISpecial[]> {
    const today = new Date();

    return this.getAll().pipe(
      switchMap((specials) =>
        from(specials).pipe(
          filter(special => {
            const start = new Date(special.scheduleStart);
            const end = new Date(special.scheduleEnd);
            return today >= start && today <= end;
          }),
          mergeMap(special =>
            this.filesCache.getFile(special.content).pipe(
              map(file => this.domSanitizer.bypassSecurityTrustUrl(file?.objectUrl)),
              map(file => (
                { ...special, contentLocal: file }
              )),
            ),
          ),
          toArray(),
        ),
      ),
    );
  }

  public clear(): Observable<unknown> {
    return this.getAll().pipe(
      switchMap((specials) => {
        if (isUndefined(specials)) {
          return of(undefined);
        }

        return this.filesCache.bulkDelete(specials.map((special: ISpecialRaw) => special.content));
      }),
      switchMap(() => {
        return this.db.clear(collectionName);
      }),
    );
  }

  public get(id: number): Observable<ISpecial | undefined> {
    return this.db.getByKey<ISpecial>(collectionName, id).pipe(
      tap({
        next: (special) => {
          if (special) {
            this.logger.debug('Special loaded', special);
          }
          else {
            this.logger.warn('Special not found', id);
          }
        },
        error: (error) => this.logger.error('Failed to load special', id, error),
      }),
    );
  }

  public set(value: ISpecial): Observable<ISpecial> {
    return this.db.update<ISpecial>(collectionName, value).pipe(
      tap({
        next: (entry) => this.logger.debug('Special set', entry),
        error: (error) => this.logger.error('Failed to update special', value, error),
      }),
    );
  }

  public delete(special: ISpecial): Observable<ISpecial | undefined> {
    return this.db.deleteByKey(collectionName, special.id).pipe(
      map((deleted) => deleted ? special : undefined),
      tap({
        next: (entry) => {
          if (entry) {
            this.logger.debug('Special deleted', entry);
          }
          else {
            this.logger.warn('Special not found', special);
          }
        },
        error: (error) => this.logger.error('Failed to delete special', special, error),
      }),
    );
  }

  public getAll(): Observable<ISpecial[]> {
    return this.db.getAll<ISpecial>(collectionName).pipe(
      tap({
        next: (entries) => this.logger.debug('Specials all loaded', entries),
        error: (error) => this.logger.error('Failed to load all specials', error),
      }),
    );
  }

}
