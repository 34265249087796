import { Component, DestroyRef, Inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CORE_AUTH_PAGE_PATH, CORE_DEBUG, CORE_MAIN_PAGE_PATH } from '../core.tokens';
import { IRestaurantTable } from './models';
import { AuthService, RestaurantTableService } from './services';
import { DebugComponent } from './components';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'core-root',
  standalone: true,
  templateUrl: './root.component.html',
  imports: [
    RouterOutlet,
    AsyncPipe,
    DebugComponent,
  ],
})
export class RootComponent {

  public readonly authorization$ = this.auth.authorization$;

  constructor(
    @Inject(CORE_DEBUG) public readonly debug: boolean,
    @Inject(CORE_MAIN_PAGE_PATH) private readonly mainPagePath: string,
    @Inject(CORE_AUTH_PAGE_PATH) private readonly authPagePath: string,
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly auth: AuthService,
    private readonly restaurantTable: RestaurantTableService,
  ) {
    this.translate.addLangs(['en-CA', 'fr-CA']);
    this.translate.setDefaultLang('en-CA');

    this.auth.logined$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.router.navigate(['/', this.mainPagePath]);
    });

    this.auth.logouted$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.router.navigate(['/', this.authPagePath]);
    });

    this.restaurantTable.table$.pipe(
      filter((table): table is IRestaurantTable => table !== null),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((table) => {
      // Set language for restaurant
      this.translate.setDefaultLang(table ? table.language : 'en-CA');
    });
  }

}
