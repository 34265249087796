import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { WatchdogService } from './lib/services';
import {
  CORE_APP_VERSION,
  CORE_DEVICE_LOCALE,
  CORE_DEVICE_NUMBERING_SYSTEM,
  CORE_DEVICE_TIMEZONE,
  CORE_STARTUP_DATE,
} from './core.tokens';

export const initSentry = (dsn: string, environment: string) => {
  Sentry.init({
    dsn,
    environment,
  });
};

export const provideCoreSentryErrorHandler = () => (
  [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        extractor(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown {
          console.error('Sentry Error Handler:', error);
          return defaultExtractor(error);
        },
      }),
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (
        startupDate: Date,
        appVersion: string,
        deviceTimezone: string,
        deviceLocale: string,
        deviceNumberingSystem: string,
      ) => () => {
        Sentry.setTag('startup_year', startupDate.getUTCFullYear().toString());
        Sentry.setTag('startup_month', startupDate.getUTCMonth().toString());
        Sentry.setTag('startup_day', startupDate.getUTCDate().toString());
        Sentry.setTag('version', appVersion);
        Sentry.setTag('device_timezone', deviceTimezone);
        Sentry.setTag('device_locale', deviceLocale);
        Sentry.setTag('device_numbering_system', deviceNumberingSystem);
      },
      deps: [
        CORE_STARTUP_DATE,
        CORE_APP_VERSION,
        CORE_DEVICE_TIMEZONE,
        CORE_DEVICE_LOCALE,
        CORE_DEVICE_NUMBERING_SYSTEM,
      ],
    },
  ]
);

export const provideCoreSentryTrace = () => (
  [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (
        watchdog: WatchdogService,
      ) => () => {
        const logger = watchdog.tag('App', 'red');
        logger.log('Trace Initialized');
      },
      deps: [WatchdogService, Sentry.TraceService],
    },
  ]
);
