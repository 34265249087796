import moment from 'moment-timezone';
import { Duration, Moment } from 'moment';

export interface IHappyHoursPeriodRaw {
  id: number;
  startDay: number;
  startTime: string;
  endDay: number;
  endTime: string;
}

export interface IPeriodTime {
  hours: number;
  minutes: number;
}

export interface IHappyHoursPeriod {
  id: number;
  startDay: number;
  startTime: IPeriodTime;
  endDay: number;
  endTime: IPeriodTime;
}

export class HappyHoursPeriodModel implements IHappyHoursPeriod {
  public id: number;
  public startDay: number;
  public startTime: IPeriodTime
  public endDay: number;
  public endTime: IPeriodTime;

  constructor(period: IHappyHoursPeriod) {
    this.id = period.id;
    this.startDay = period.startDay;
    this.startTime = period.startTime;
    this.endDay = period.endDay;
    this.endTime = period.endTime;
  }

  public get timeStart(): Moment {
    return HappyHoursPeriodModel.hhTimeToDate(this.startTime);
  }

  public get timeEnd(): Moment {
    return HappyHoursPeriodModel.hhTimeToDate(this.endTime);
  }

  public get timeLeftBeforeStart(): Duration {
    const now = moment();
    const startMoment = this.timeStart;

    if (this.startDay > now.day() || (this.startDay === now.day() && startMoment.isAfter(now))) {
      startMoment.day(this.startDay);
      return moment.duration(startMoment.diff(now));
    } else {
      startMoment.day(this.startDay + 7);
      return moment.duration(startMoment.diff(now));
    }
  }

  public get timeLeftBeforeEnd(): Duration {
    const now = moment();
    const endMoment = this.timeEnd;

    if (this.endDay > now.day() || (this.endDay === now.day() && endMoment.isAfter(now))) {
      endMoment.day(this.endDay);
      return moment.duration(endMoment.diff(now));
    } else {
      endMoment.day(this.endDay + 7);
      return moment.duration(endMoment.diff(now));
    }
  }

  public static parseRaw(raw: IHappyHoursPeriodRaw): IHappyHoursPeriod {
    const timeStart = moment(raw.startTime, 'HH:mm:ss');
    const timeEnd = moment(raw.endTime, 'HH:mm:ss');

    return new HappyHoursPeriodModel({
      id: raw.id,
      startDay: raw.startDay,
      startTime: { hours: timeStart.hours(), minutes: timeStart.minutes() },
      endDay: raw.endDay,
      endTime: { hours: timeEnd.hours(), minutes: timeEnd.minutes() },
    })
  }

  public static hhTimeToDate(time: IPeriodTime): Moment {
    const date = moment();
    date.set({
      hours: time.hours,
      minutes: time.minutes,
      seconds: 0,
    });

    return date;
  }
}
