import { DestroyRef, Injectable } from '@angular/core';
import { BehaviorSubject, first, forkJoin, Observable, of, skipWhile } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { arrayDiff, arrayDiffIdCompare, isDefined, isNotNull, isNumber, isUndefined, tapSubscribed } from '../utils';
import { IMenu, IMenuRaw } from '../models';
import { AuthService } from './auth.service';
import { FileCacheService } from './file-cache.service';
import { KeyValueStorageService } from './key-value.storage.service';
import { WatchdogService } from './watchdog.service';
import { DWallIntercom } from './dwall-intercom';
import { RestaurantTableService } from './restaurant-table.service';

interface ToUpdate<T> {
  origin: T;
  value: T;
}

const storageKey = 'restaurantMenus';

@Injectable()
export class MenusService {

  public readonly loading$ = new BehaviorSubject<boolean>(true);
  public readonly menus$ = new BehaviorSubject<IMenu[]>([]);
  private readonly logger = this.watchdog.tag('Menu Service', 'green');

  public constructor(
    private readonly destroyRef: DestroyRef,
    private readonly domSanitizer: DomSanitizer,
    private readonly watchdog: WatchdogService,
    private readonly auth: AuthService,
    private readonly filesCache: FileCacheService,
    private readonly keyValueStorage: KeyValueStorageService,
    private readonly dwallIntercom: DWallIntercom,
    private readonly restaurantTable: RestaurantTableService,
  ) {
    this.loading$.next(true);

    this.get().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((menus) => {
      this.logger.info('Menus data initialized.', menus);
      this.menus$.next(menus);
      this.loading$.next(false);
    });

    this.auth.logouted$.pipe(
      switchMap(() => this.clear()),
      tap(() => this.logger.info('Cleared menus on logout')),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public get(): Observable<IMenu[]> {
    const processFiles = (pages: string[]) => this.filesCache.getFiles(pages).pipe(
      map((files) => {
        return files.map((file) => this.domSanitizer.bypassSecurityTrustUrl(
          file.objectUrl,
        ));
      }),
    );

    const processMenus = (menus: IMenuRaw[]) => {
      if (menus.length === 0) {
        return of([]);
      }

      return forkJoin(
        menus.map((menu) => processFiles(menu.pages).pipe(
          map((files) => {
            return { ...menu, pagesLocal: files };
          }),
        )),
      );
    };

    const processIcons = (menus: IMenu[]): Observable<IMenu[]> => {
      if (menus.length === 0) {
        return of([]);
      }

      return forkJoin(
        menus.map(menu => {
          if (menu.icon) {
            return this.filesCache.getFile(menu.icon).pipe(
              map(file => (
                {
                  ...menu,
                  iconLocal: this.domSanitizer.bypassSecurityTrustUrl(file?.objectUrl),
                }
              )),
            );
          }

          return of(menu);
        }),
      );
    };

    return this.keyValueStorage.get<IMenuRaw[]>(storageKey).pipe(
      filter(isDefined),
      map((entry) => entry.value),
      switchMap(processMenus),
      switchMap(processIcons),
    );
  }

  public getMenuForCurrentMedia(): Observable<IMenu | null> {
    const currentMedia = this.dwallIntercom.currentMedia$.getValue();

    if (!currentMedia) {
      return of(null);
    }

    return this.restaurantTable.table$.pipe(
      filter(isNotNull),
      map((table) => table.clickableMediaConfig),
      filter(isNotNull),
      map((data) => data.find((media) => isNumber(media.menuId) && media.id === currentMedia.id)),
      switchMap((media) => {
        if (!media) {
          return of(null);
        }

        return this.menus$.pipe(
          map((menus) => menus.find((menu) => menu.id === media.menuId)),
          first(isDefined),
        );
      }),
    );
  }

  public clear(): Observable<unknown> {
    return this.keyValueStorage.get<IMenuRaw[]>(storageKey).pipe(
      switchMap((entry) => {
        if (isUndefined(entry)) {
          return of(entry);
        }

        const pages = entry.value.map((menu: IMenuRaw) => {
          return menu.pages;
        }).flat();

        return this.filesCache.bulkDelete(pages);
      }),
      switchMap(() => {
        return this.keyValueStorage.delete(storageKey);
      }),
      tap(() => this.menus$.next([])),
    );
  }

  public sync(newMenus: IMenuRaw[]): Observable<IMenu[]> {
    this.loading$.next(true);

    return this.keyValueStorage.get<IMenuRaw[]>(storageKey).pipe(
      tapSubscribed(() => this.logger.debug('Start sync menus')),
      map((entry) => {
        const currentMenus = entry?.value ?? [];

        return {
          add: arrayDiff(newMenus, currentMenus, arrayDiffIdCompare),
          update: currentMenus.reduce<ToUpdate<IMenuRaw>[]>((acc, origin) => {
            const value = newMenus.find((menu) => menu.id === origin.id);
            if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
              acc.push({ origin, value });
            }
            return acc;
          }, []),
          delete: arrayDiff(currentMenus, newMenus, arrayDiffIdCompare),
        };
      }),
      skipWhile((changed) => {
        if (
          changed.add.length === 0 &&
          changed.update.length === 0 &&
          changed.delete.length === 0
        ) {
          this.logger.debug('Nothing to sync');
          return true;
        }

        return false;
      }),
      switchMap((changed) => forkJoin([
        this.toAdd(changed.add),
        this.toUpdate(changed.update),
        this.toDelete(changed.delete),
      ])),
      switchMap(() => this.keyValueStorage.set({
        key: storageKey,
        value: newMenus,
      })),
      switchMap(() => this.get().pipe(
        tap((menus) => this.menus$.next(menus)),
      )),
      finalize(() => this.loading$.next(false)),
      tap({
        next: (menus) => this.logger.debug('Menus synced', menus),
        error: (error) => this.logger.error('Failed to sync menus', error),
      }),
    );
  }

  private toAdd(menus: IMenuRaw[]): Observable<IMenuRaw[]> {
    if (menus.length === 0) {
      return of([]);
    }

    const menuFilesDownload$ = (menu: IMenuRaw) => {
      const downloadMenuPages$ = this.filesCache.getFiles(menu.pages).pipe(
        tap(files => this.logger.debug(`Downloaded pages for menu ${ menu.id }`, files)),
      );

      if (menu.icon) {
        const downloadMenuIcon$ = this.filesCache.getFile(menu.icon).pipe(
          tap(file => this.logger.debug(`Downloaded icon for menu ${ menu.id }`, file)),
        );

        return forkJoin([
          downloadMenuPages$,
          downloadMenuIcon$,
        ]).pipe(
          map(() => menu),
        );
      }

      return downloadMenuPages$.pipe(
        map(() => menu),
      );
    };

    return of(menus).pipe(
      tap((menus) => this.logger.debug('Menus to add', menus)),
      switchMap((menus) => forkJoin(
        menus.map((menu) => menuFilesDownload$(menu)),
      )),
      tap((menus) => this.logger.debug('Menus added', menus)),
    );
  }

  private toUpdate(menus: ToUpdate<IMenuRaw>[]): Observable<IMenuRaw[]> {
    if (menus.length === 0) {
      return of([]);
    }

    const menuFilesDownload$ = (menu: ToUpdate<IMenuRaw>) => {
      const jobs = [];

      if (arrayDiff(menu.origin.pages, menu.value.pages).length > 0) {
        jobs.push(
          this.filesCache.bulkDelete(menu.origin.pages).pipe(
            switchMap(() => this.filesCache.getFiles(menu.value.pages)),
          ),
        );
      }

      if (menu.origin.icon !== menu.value.icon) {
        if (menu.origin.icon) {
          jobs.push(this.filesCache.delete(menu.origin.icon));
        }

        if (menu.value.icon) {
          jobs.push(this.filesCache.getFile(menu.value.icon));
        }
      }

      return jobs.length > 0 ? forkJoin(jobs) : of([]);
    };

    return of(menus).pipe(
      tap((menus) => this.logger.debug('Menus to update', menus)),
      switchMap((menus) => forkJoin(
        menus.map((menu) => menuFilesDownload$(menu)),
      )),
      map(() => menus.map(({ value }) => value)),
      tap((menus) => this.logger.debug('Menus updated', menus)),
    );
  }

  private toDelete(menus: IMenuRaw[]): Observable<unknown> {
    if (menus.length === 0) {
      return of([]);
    }

    return of(menus).pipe(
      tap((menus) => this.logger.debug('Menus to delete', menus)),
      switchMap((menus) => this.filesCache.bulkDelete(
        menus.map(menu => {
          if (menu.icon) {
            return [menu.icon, ...menu.pages];
          }

          return menu.pages;
        }).flat(),
      )),
      tap((menus) => this.logger.debug('Menus deleted', menus)),
    );
  }

}
