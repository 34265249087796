import { provideRouter, Routes } from '@angular/router';
import { AuthPageComponent } from './lib/pages';

export const provideCoreRouter = (
  mainPagePath: string,
  authPagePath: string,
  routes: Routes,
) => provideRouter([
  {
    path: '',
    pathMatch: 'full',
    redirectTo: mainPagePath,
  },
  {
    path: authPagePath,
    component: AuthPageComponent,
  },
  ...routes,
]);
