import { Component, OnInit } from '@angular/core';
import { NgForOf } from '@angular/common';
import { Router } from '@angular/router';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { BehaviorSubject } from 'rxjs';
import { IMenu, MenusService } from '@core';

@Component({
  standalone: true,
  selector: 'app-special-offer',
  templateUrl: './special-offer.component.html',
  styleUrls: ['./special-offer.component.scss'],
  imports: [
    PinchZoomModule,
    NgForOf,
  ],
})
export class SpecialOfferComponent implements OnInit {

  public readonly pinchZoomProperties = {
    wheel: false,
    autoZoomOut: true,
    backgroundColor: 'rgba(240, 240, 243, 0.9)',
  };

  public readonly menu$ = new BehaviorSubject<IMenu | null>(null);

  constructor(
    private readonly router: Router,
    private readonly menusService: MenusService,
  ) {}

  get menu(): IMenu | null {
    return this.menu$.getValue();
  }

  ngOnInit(): void {
    this.menusService.getMenuForCurrentMedia().subscribe((menu) => {
      if (menu) {
        this.menu$.next(menu);
      }
      else {
        this.router.navigate(['main/home']);
      }
    });
  }

}
