import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'core-debug-ui-bars',
  standalone: true,
  templateUrl: './debug-bars.component.html',
  styleUrls: ['./debug-bars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugBarsComponent {

  public value = input.required<number[]>();

}
