import { Component } from '@angular/core';
import { RootComponent } from '@core/lib/root.component';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    RootComponent,
  ],
})
export class AppComponent {}
