import { DestroyRef, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IDeviceWorkSchedule, IKeyValue } from '../models';
import { KeyValueStorageService } from './key-value.storage.service';
import { DWallIntercom } from './dwall-intercom';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { WatchdogService } from './watchdog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class DeviceWorkScheduleService {

  protected readonly deviceWorkScheduleKey = 'deviceWorkSchedule';
  private readonly logger = this.watchdog.tag('Device Work Schedule Service', 'cyan');
  private readonly deviceWorkSchedule: IDeviceWorkSchedule[] = [
    { active: false, day: 0, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 1, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 2, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 3, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 4, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 5, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 6, timeFrom: '00:00', timeTo: '00:00' },
  ];
  private schedule: IDeviceWorkSchedule[] = [];

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly keyValueStorage: KeyValueStorageService,
    private readonly dwallIntercom: DWallIntercom,
    private readonly auth: AuthService,
    private readonly watchdog: WatchdogService,
  ) {
    this.dwallIntercom.messages$.pipe(
      filter((message) => message.event === 'device.work_schedule.failed'),
      debounceTime(5000),
      switchMap(() => this.keyValueStorage.get(this.deviceWorkScheduleKey)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((schedule) => {
      this.dwallIntercom.call('device.work_schedule', schedule?.value ?? this.deviceWorkSchedule);
    });

    this.auth.logined$.pipe(
      switchMap(() => this.keyValueStorage.get<IDeviceWorkSchedule[]>(this.deviceWorkScheduleKey)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((schedule) => {
      if (schedule?.value) {
        this.schedule = this.deviceWorkSchedule.map(d => {
          const newDay = schedule.value.find((ell: IDeviceWorkSchedule) => d.day === ell.day);
          return newDay ? newDay : d;
        });
      }

      this.dwallIntercom.call(
        'device.work_schedule',
        this.schedule.length > 0 ? this.schedule : this.deviceWorkSchedule,
      );

      this.logger.log('Init', this.schedule.length > 0 ? this.schedule : this.deviceWorkSchedule);
    });

    this.auth.logouted$.pipe(
      tap(() => {
        this.dwallIntercom.call('device.work_schedule', this.deviceWorkSchedule);
        this.logger.log('Clear on logout', this.deviceWorkSchedule);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public sync(schedule: IDeviceWorkSchedule[]): void {
    this.keyValueStorage.get(this.deviceWorkScheduleKey).pipe(
      map((entry) => entry?.value),
      switchMap((scheduleLocal) => {
        if (JSON.stringify(scheduleLocal) !== JSON.stringify(schedule)) {
          this.schedule = this.deviceWorkSchedule.map(d => {
            const newDay = schedule.find((ell: IDeviceWorkSchedule) => d.day === ell.day);
            if (newDay) {
              return {
                active: newDay.active,
                day: newDay.day,
                timeFrom: newDay.timeFrom.split(':').slice(0, 2).join(':'),
                timeTo: newDay.timeTo.split(':').slice(0, 2).join(':'),
              };
            }
            else {
              return {
                active: false,
                day: d.day,
                timeFrom: '00:00',
                timeTo: '00:00',
              };
            }
          });
          this.dwallIntercom.call('device.work_schedule', this.schedule);
          this.logger.log('Update Device Work Schedule', this.schedule);
          return this.setWorkSchedule(this.schedule);
        }
        else {
          return of(schedule);
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public setWorkSchedule(schedule: IDeviceWorkSchedule[]): Observable<IKeyValue<IDeviceWorkSchedule[]>> {
    return this.keyValueStorage.delete(this.deviceWorkScheduleKey).pipe(
      switchMap(() => {
        return this.keyValueStorage.set({
          key: this.deviceWorkScheduleKey,
          value: schedule,
        });
      }),
    );
  }

}
