import { Routes } from '@angular/router';
import { MainPageComponent } from './pages';
import { MenuTabComponent } from './pages/main-page/menu-tab/menu-tab.component';
import { HomeTabComponent } from './pages/main-page/home-tab/home-tab.component';
import { SpecialOfferComponent } from './pages/main-page/special-offer/special-offer.component';
import { InvoiceTabComponent } from './pages/main-page/invoice-tab/invoice-tab.component';
import { AppTabComponent } from './pages/main-page/app-tab/app-tab.component';
import { GiftTabComponent } from './pages/main-page/gift-tab/gift-tab.component';

export const appRoutes: Routes = [
  {
    path: 'main',
    component: MainPageComponent,
    children: [
      {
        path: 'menu',
        component: MenuTabComponent,
        data: { animation: 'PlayTab', screen: 'Menu' },
      },
      {
        path: 'home',
        component: HomeTabComponent,
        data: { animation: 'HomeTab', screen: 'Home' },
        children: [
          {
            path: 'special-offer',
            component: SpecialOfferComponent,
            data: { animation: 'SpecialOffer', screen: 'SpecialOffer' },
          },
        ],
      },
      {
        path: 'invoice',
        component: InvoiceTabComponent,
        data: { animation: 'GetRewardedTab', screen: 'Invoice' },
      },
      {
        path: 'app',
        component: AppTabComponent,
        data: { animation: 'WatchTab', screen: 'App' },
      },
      {
        path: 'gift',
        component: GiftTabComponent,
        data: { animation: 'MoreTab', screen: 'Gift' },
      },
    ],
  },
];
