<div class="box">
  <div class="main">
    <div class="text">
      <h2>Give the gift of</h2>
      <h1>Original JOE'S</h1>
      <h2>With the ultimate dining card</h2>
    </div>
    <img class="qrImg" src='/images/cardQr.png' alt=''>
  </div>

  <div class="cards">
    <div class="one">
      <img src='/images/GreyCard.jpg' alt=''>
    </div>
    <div class="two">
      <img src='/images/CardRed.jpg' alt=''>
    </div>
  </div>
</div>
