import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined, isString } from '../utils';
import { IDWallMedia } from '../models';
import { WatchdogService } from './watchdog.service';

type IntercomMethod =
  'init'
  | 'device.turnOff'
  | 'device.work_schedule'
  | 'playlist.get_items'
  | 'playlist.get_playing_now'
  | 'playlist.stop'
  | 'playlist.resume'
  | 'ambient_light.on'
  | 'ambient_light.off'
  | 'battery.level.get'
  | 'brightness.get'
  | 'brightness.set'
  | 'demo.method';
type IntercomMethodData = any;

type IntercomEvent =
  'device.work_schedule.failed'
  | 'playlist.items'
  | 'playlist.playing_now'
  | 'playlist.playing_finished'
  | 'battery.level'
  | 'brightness.level'
  | 'demo.mode'
  | 'demo.events';
type IntercomEventData = any;

export interface IIntercomEventMessage {
  type: 'dwall-events';
  version: string;
  event: IntercomEvent;
  data: IntercomEventData;
}

export interface IIntercomMethodMessage {
  type: 'dwall-events';
  version: string;
  method: IntercomMethod;
  data: IntercomMethodData;
}

@UntilDestroy()
@Injectable()
export class DWallIntercom {

  public readonly messages$ = new Subject<IIntercomEventMessage>();
  public readonly currentMedia$ = new BehaviorSubject<IDWallMedia | null>(null);
  private readonly logger = this.watchdog.tag('DWAll Intercom Service', 'cyan');
  private readonly port$ = new BehaviorSubject<MessagePort | null>(null);

  constructor(
    private readonly watchdog: WatchdogService,
  ) {}

  get connected(): boolean {
    return !!this.port$.getValue();
  }

  get connected$(): Observable<boolean> {
    return this.port$.pipe(map((port) => !!port));
  }

  private static methodMessage(method: IntercomMethod, data: IntercomMethodData = null): IIntercomMethodMessage {
    return {
      type: 'dwall-events',
      version: '0.0.1',
      method,
      data,
    };
  }

  public initialize(): void {
    this.logger.info('Initializing');

    fromEvent<MessageEvent>(window, 'message').pipe(
      filter((event) => {
        return event.data === 'dwall-handshake' && isDefined(event.ports) && isDefined(event.ports[0]);
      }),
      tap((event) => this.logger.debug('Handshake', JSON.stringify(event))),
      map((event) => event.ports[0]),
      tap((port) => this.port$.next(port)),
      switchMap((port) => {
        port.start();
        this.call('init');

        return fromEvent<MessageEvent>(port, 'message');
      }),
      tap((event) => this.logger.debug('Received raw:', event)),
      map((event) => {
        this.logger.debug('Message type:', typeof event.data);

        // TODO: Remove this hack when Android will be fixed
        if (isString(event.data)) {
          // @eslint-disable-next-line
          const data = JSON.parse(event.data.replace('\/', ''));

          this.logger.debug('Message parse:', data);

          return data;
        }

        return event.data;
      }),
      filter((message) => {
        return message.type && message.version && message.event;
      }),
    ).pipe(
      untilDestroyed(this),
    ).subscribe((message) => {
      this.logger.debug('Received:', message);

      this.messages$.next(message);
    });

    this.messages$.pipe(
      filter((message) => message.event === 'playlist.playing_now'),
      pluck('data'),
    ).pipe(
      untilDestroyed(this),
    ).subscribe((data) => {
      this.currentMedia$.next(data);
    });
  }

  call(method: IntercomMethod, data: IntercomMethodData = null): boolean {
    const port = this.port$.getValue();
    if (!port) {
      return false;
    }

    const message = DWallIntercom.methodMessage(method, data);
    // TODO: Remove this hack when Android will be fixed
    port.postMessage(JSON.stringify(message));
    this.logger.debug('Sent:', message);

    return true;
  }

}
