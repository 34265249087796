import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { ActivityLogsStorageService } from './activity-logs.storage.service';
import { UserActivityLogs } from '../models';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  mergeMap,
  repeatWhen,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import { NetworkService } from './network.service';
import { CORE_APP_ENV, CORE_APP_VERSION } from '../../core.tokens';

export const CORE_USER_ACTIVITY_SECTION = new InjectionToken<BehaviorSubject<string>>('CORE_USER_ACTIVITY_SECTION');

@Injectable()
export class ClickTrackingService {

  constructor(
    @Inject(CORE_APP_ENV) private readonly appEnv: string,
    @Inject(CORE_APP_VERSION) private readonly appVersion: string,
    private http: HttpClient,
    private logsStorage: ActivityLogsStorageService,
    private network: NetworkService,
  ) {
    this.sendLogsPeriodical(5000).subscribe();
  }

  public trackClick(event: MouseEvent, screen: string, section: string, target: any): void {

    const eventData: UserActivityLogs = {
      app: this.appEnv,
      appVersion: this.appVersion,
      action: event.type,
      target,
      screen,
      section,
      screenX: event.screenX,
      screenY: event.screenY,
      capturedAt: new Date(),
    };

    this.logsStorage.add(eventData).subscribe({
      next: () => this.sendLogs(),
      error: (err) => console.error('Error adding data:', err),
    });
  }

  public sendLogsPeriodical(period: number): Observable<any> {
    const repeatDelay = () => this.network.status$.pipe(
      distinctUntilChanged(),
      filter((s) => s),
      tap(() => console.log(`Sending delay until ${ new Date(Date.now() + period).toLocaleString() }`)),
      delay(period),
      tap(() => console.log('Sending restart')),
    );

    return this.sendLogs().pipe(
      repeatWhen((completed) => completed.pipe(switchMap(repeatDelay))),
      retryWhen((errors) => errors.pipe(switchMap(repeatDelay))),
    );
  }

  deleteSentLogs(): Observable<unknown> {
    return this.logsStorage.getSent().pipe(
      mergeMap(logs => {
        if (logs.length === 0) {
          return of();
        }
        const deletionObservables: Observable<void>[] = logs.map(log => {
          if (log.id !== undefined) {
            return this.logsStorage.delete(log.id);
          }
          else {
            return of();
          }
        });
        return forkJoin(deletionObservables);
      }),
    );
  }

  private sendLogs(): Observable<any> {
    return this.logsStorage.getUnsent().pipe(
      mergeMap(logs => {
        if (logs.length <= 10) {
          return EMPTY;
        }

        return this.http.post<UserActivityLogs[]>('@api_host/clicks/report', { records: logs }).pipe(
          tap(response => {
            console.log('Logs sent successfully', response);
            this.updateSentLogs(logs);
          }),
          catchError(error => {
            console.error('Error sending logs:', error);
            return EMPTY;
          }),
        );
      }),
    );
  }

  private updateSentLogs(logs: UserActivityLogs[]): void {
    const currentTime = new Date();
    const updatedLogs: Observable<UserActivityLogs>[] = logs.map(log => {
      log.sentAt = currentTime;
      return this.logsStorage.update(log);
    });

    forkJoin(updatedLogs).subscribe({
      next: () => {
        console.log('All sent logs are marked with sent time');
        this.deleteSentLogs().subscribe({
          next: () => {
            console.log('All sent logs have been deleted successfully');
          },
          error: err => console.error('Error delete logs:', err),
        });
      },
      error: err => console.error('Error updating logs:', err),
    });
  }
}
