import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeyValueStorageService } from './key-value.storage.service';
import { WatchdogService } from './watchdog.service';

@UntilDestroy()
@Injectable()
export class AuthService {

  private static readonly tokenKey = 'token';
  public readonly demoMode$ = new BehaviorSubject<boolean>(false);
  private readonly logger = this.watchdog.tag('Auth Service', 'green');
  private readonly token$ = new BehaviorSubject<string | null | undefined>(undefined);
  public readonly authorization$ = this.token$.pipe(
    map((token) => token === undefined),
    distinctUntilChanged(),
  );
  private readonly authorized$ = this.token$.pipe(
    distinctUntilChanged(),
    filter((token) => token !== undefined),
    map((token) => !!token),
  );
  public readonly logouted$ = this.authorized$.pipe(
    distinctUntilChanged(),
    filter((yes) => !yes),
    map(() => true),
  );
  public readonly logined$ = this.authorized$.pipe(
    distinctUntilChanged(),
    filter((yes) => yes),
    map(() => true),
  );

  constructor(
    private readonly watchdog: WatchdogService,
    private readonly keyValueStorage: KeyValueStorageService,
  ) {
    merge(
      this.authorization$.pipe(
        map((status) => status ? 'Authorization pending' : 'Authorization complete'),
      ),
      this.logined$.pipe(map(() => 'Authorized')),
      this.logouted$.pipe(map(() => 'Unauthorized')),
    ).pipe(
      untilDestroyed(this),
    ).subscribe((status) => {
      this.logger.info(status);
    });

    const tokenFlow$ = this.keyValueStorage.get<string>(AuthService.tokenKey).pipe(
      map((token) => {
        if (token && token.value) {
          return token.value;
        }

        return localStorage.getItem(AuthService.tokenKey);
      }),
      tap((token) => {
        if (token) {
          this.logger.info('Token found in storage');
          this.saveToken(token);
        }
        else {
          this.logger.info('Token not found in storage');
          this.removeToken();
        }
      }),
    );

    this.demoMode$.pipe(
      distinctUntilChanged(),
      switchMap((demoMode) => {
        if (demoMode) {
          this.token$.next('demo');
          this.logger.debug('Demo mode enabled');

          return of(null);
        }

        return tokenFlow$;
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  public get isDemoMode(): boolean {
    return this.demoMode$.getValue();
  }

  public login(token: string): void {
    this.logger.debug('Login');
    this.saveToken(token);
  }

  public logout(): void {
    this.logger.debug('Logout');
    this.removeToken();
  }

  public getToken(): string | null {
    return this.token$.getValue() ?? null;
  }

  private saveToken(token: string): void {
    this.keyValueStorage.set({
      key: AuthService.tokenKey,
      value: token,
    }).subscribe();
    this.token$.next(token);
    localStorage.setItem(AuthService.tokenKey, token);
  }

  private removeToken(): void {
    this.keyValueStorage.delete(AuthService.tokenKey).subscribe();
    this.token$.next(null);
    localStorage.removeItem(AuthService.tokenKey);
  }

}
