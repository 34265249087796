import {
  CORE_APP_ENV,
  CORE_APP_NAME,
  CORE_APP_VERSION,
  CORE_DEVICE_LOCALE,
  CORE_DEVICE_TIMEZONE,
  CORE_STARTUP_DATE,
  CORE_WEBSOCKET_BASE_URL,
} from '../../core.tokens';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, merge, of, Subscription, timer } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  pluck,
  share,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { AuthService } from './auth.service';
import { RestaurantTableService } from './restaurant-table.service';
import { NetworkService } from './network.service';
import { DWallIntercom } from './dwall-intercom';
import { HappyHoursService } from './happy-hours.service';
import { MenusService } from './menus.service';
import { InvoiceQrcodeImageService } from './invoice-qrcode-image.service';
import { InteractionService } from './interaction.service';
import { IRestaurantTable } from '../models';
import { SpecialsService } from './specials.service';
import { WatchdogService } from './watchdog.service';
import { DeviceWorkScheduleService } from './device-work-schedule.service';
import { UpdateService } from './update.service';

@UntilDestroy({ checkProperties: true })
@Injectable()
export class WebsocketService {

  public readonly status$ = new BehaviorSubject<boolean>(false);
  public readonly connectionCount$ = new BehaviorSubject<number>(0);
  public readonly lastConnectionAt$ = new BehaviorSubject<Date | null>(null);
  public readonly lastDisconnectionAt$ = new BehaviorSubject<Date | null>(null);
  private readonly logger = this.watchdog.tag('Websocket Service', 'magenta');
  private readonly ws$ = webSocket({
    url: `${ this.websocketBaseUrl }/ws/main`,
    openObserver: {
      next: () => {
        this.logger.debug('Open');
        this.sendAuthToken();
      },
    },
    closingObserver: {
      next: () => this.logger.debug('Closing'),
    },
    closeObserver: {
      next: () => {
        this.logger.debug('Close');
        this.status$.next(false);
        this.lastDisconnectionAt$.next(new Date());
      },
    },
  });

  private wsSub: Subscription | null = null;
  private timeoutSub: Subscription | null = null;
  private heartbeatSub: Subscription | null = null;

  constructor(
    @Inject(CORE_STARTUP_DATE) private readonly startupDate: Date,
    @Inject(CORE_DEVICE_LOCALE) private readonly deviceLocale: string,
    @Inject(CORE_DEVICE_TIMEZONE) private readonly deviceTimezone: string,
    @Inject(CORE_APP_NAME) private readonly appName: string,
    @Inject(CORE_APP_VERSION) private readonly appVersion: string,
    @Inject(CORE_APP_ENV) private readonly appEnv: string,
    @Inject(CORE_WEBSOCKET_BASE_URL) private readonly websocketBaseUrl: string,
    private readonly translate: TranslateService,
    private readonly update: UpdateService,
    private readonly watchdog: WatchdogService,
    private readonly auth: AuthService,
    private readonly interaction: InteractionService,
    private readonly restaurantTable: RestaurantTableService,
    private readonly network: NetworkService,
    private readonly dwallIntercom: DWallIntercom,
    private readonly happyHours: HappyHoursService,
    private readonly menus: MenusService,
    private readonly invoiceQrcodeImage: InvoiceQrcodeImageService,
    private readonly specials: SpecialsService,
    private readonly deviceWorkSchedule: DeviceWorkScheduleService,
  ) {}

  public get isConnected(): boolean {
    return this.status$.getValue();
  }

  private get table(): IRestaurantTable | null {
    return this.restaurantTable.table$.getValue();
  }

  private get tableId(): number | null {
    return this.table?.tableId ?? null;
  }

  private get callWaiter(): boolean {
    return this.restaurantTable.callWaiter$.getValue();
  }

  private get callWaiterToPay(): boolean {
    return this.restaurantTable.callWaiterToPay$.getValue();
  }

  private get callWaiterToRepeat(): boolean {
    return this.restaurantTable.callWaiterToRepeat$.getValue();
  }

  private get userInteracts(): boolean {
    return !this.interaction.idle;
  }

  public initialize(): void {
    this.logger.info('Initializing');

    this.auth.logined$.pipe(
      filter(() => !this.auth.isDemoMode),
      switchMap(() => this.network.status$.pipe(
        distinctUntilChanged(),
        filter((status) => status),
      )),
      untilDestroyed(this),
    ).subscribe(() => {
      this.initWebSocket();
    });

    merge(
      this.auth.logouted$,
      this.auth.demoMode$.pipe(
        distinctUntilChanged(),
        filter((demo) => demo),
      ),
      this.network.status$.pipe(
        distinctUntilChanged(),
        filter((status) => !status),
      ),
    ).pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.unsubscribeAll();
    });

    this.demoInitialize();
  }

  public updateTableInfo(data: any): void {
    if (data.clickableMediaConfig) {
      data.clickableMediaConfig = JSON.parse(data.clickableMediaConfig);
    }

    this.restaurantTable.updateData(
      data,
    ).pipe(
      untilDestroyed(this),
    ).subscribe();

    if (data.language) {
      if (!this.userInteracts) {
        this.translate.use(data.language);
      }

      this.translate.setDefaultLang(data.language);
    }

    if (data.happyMenuPeriods) {
      this.happyHours.updateByCollection(data.happyMenuPeriods).pipe(
        untilDestroyed(this),
      ).subscribe();
    }
    else {
      this.happyHours.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    }

    if (data.menus) {
      this.menus.sync(
        data.menus,
      ).pipe(
        untilDestroyed(this),
      ).subscribe();
    }
    else {
      this.menus.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    }

    if (data.specials) {
      this.specials.sync(
        data.specials,
      ).pipe(
        untilDestroyed(this),
      ).subscribe();
    }

    if (data.deviceWorkSchedule) {
      this.deviceWorkSchedule.sync(data.deviceWorkSchedule);
    }

    if (data.invoiceQRCodeUrl) {
      this.invoiceQrcodeImage.update(
        data.invoiceQRCodeUrl,
      ).pipe(
        untilDestroyed(this),
      ).subscribe();
    }
    else {
      this.invoiceQrcodeImage.clear().pipe(
        untilDestroyed(this),
      ).subscribe();
    }
  }

  public send(type: string, data: any): void {
    this.logger.debug('Send:', type, data);
    this.ws$.next({ type, data });
  }

  public sendAuthToken(): void {
    this.send('login', {
      token: this.auth.getToken(),
      metadata: {
        device: {
          locale: this.deviceLocale,
          timezone: this.deviceTimezone,
          screen: {
            width: window.screen.width,
            height: window.screen.height,
          },
        },
        app: {
          name: this.appName,
          version: this.appVersion,
          environment: this.appEnv,
          startupDate: this.startupDate.toUTCString(),
          selfUpdate: {
            enabled: this.update.enabled,
            checkStrategy: this.update.updateCheckStrategyTitle,
            activateStrategy: this.update.updateActivateStrategyTitle,
          },
          userInteraction: {
            idle: {
              delay: this.interaction.idleDelay,
            },
          },
        },
      },
    });
  }

  public sendCallWaiter(): void {
    if (!this.tableId) {
      return;
    }

    if (!this.isConnected) {
      return;
    }

    this.send('echo', {
      tableId: this.tableId,
      status: this.callWaiter,
      pay: this.callWaiterToPay,
      repeat: this.callWaiterToRepeat,
    });
  }

  public sendPlaylistItems(items: []): void {
    if (!this.tableId) {
      return;
    }

    if (!this.isConnected) {
      return;
    }

    this.send('echo', {
      tableId: this.tableId,
      playlistItems: items,
    });
  }

  public sendBatteryLevel(level: number, charging = false): void {
    if (!this.tableId) {
      return;
    }

    if (!this.isConnected) {
      return;
    }

    const prevValue = this.restaurantTable.batteryLevel$.getValue();
    const prevLevel = prevValue?.level ?? 0;

    this.send('batteryLevel', {
      level, charging,
    });

    this.restaurantTable.batteryLevel$.next({
      level, charging,
    });

    this.translate.getTranslation(this.translate.defaultLang).pipe(
      pluck('pushNotification'),
      untilDestroyed(this),
    ).subscribe((text) => {
      let notificationMessage = null;

      if (prevLevel > 0.02 && level <= 0.02) {
        notificationMessage = text.batteryLess2;
      }
      else if (prevLevel > 0.1 && level <= 0.1) {
        notificationMessage = text.batteryLess10;
      }
      else if (prevLevel > 0.3 && level <= 0.3) {
        notificationMessage = text.batteryLess30;
      }

      if (notificationMessage) {
        this.send('pushNotification', {
          title: `Table ${ this.table?.tableName }`,
          body: notificationMessage,
          recipientsCategory: 'all',
        });
      }
    });
  }

  public sendCallWaiterPushNotification(): void {
    const table = this.table;

    if (!table) {
      return;
    }

    if (!this.isConnected) {
      return;
    }

    this.translate.getTranslation(this.translate.defaultLang).pipe(
      pluck('pushNotification'),
      untilDestroyed(this),
    ).subscribe((text) => {
      const messages = [];

      if (this.callWaiter) {
        messages.push(text.callWaiter);
      }

      if (this.callWaiterToPay) {
        messages.push(text.billPlease);
      }

      if (this.callWaiterToRepeat) {
        messages.push(text.oneMoreRound);
      }

      if (messages.length > 0) {
        this.send('pushNotification', {
          title: `Table ${ this.table?.tableName }`,
          body: messages.join(', '),
          recipientsCategory: 'all',
        });
      }
    });
  }

  private demoInitialize(): void {
    this.logger.debug('Initialize over intercom for demo mode');

    this.dwallIntercom.messages$.pipe(
      filter(message => message.event.startsWith('demo.')),
      untilDestroyed(this),
    ).subscribe((message) => {
      switch (message.event) {
        case 'demo.mode':
          this.status$.next(true);
          this.auth.demoMode$.next(true);
          break;
        case 'demo.events':
          this.onMessage(message.data);
          break;
      }
    });
  }

  private unsubscribeAll(): void {
    this.wsSub?.unsubscribe();
    this.wsSub = null;
    this.timeoutSub?.unsubscribe();
    this.timeoutSub = null;
    this.heartbeatSub?.unsubscribe();
    this.heartbeatSub = null;
  }

  private initWebSocket(): void {
    this.unsubscribeAll();

    const share$ = this.ws$.pipe(
      filter((response: any) => !!(
        response?.type
      )),
      catchError((error) => {
        console.error('WebSocket:', error);
        return EMPTY;
      }),
      share(),
    );

    this.timeoutSub = merge(of('init'), share$).pipe(
      switchMap(() => of('timeout').pipe(delay(10000))),
      untilDestroyed(this),
    ).subscribe((msg) => {
      console.log(`WebSocket: ${ msg }`);
      this.initWebSocket();
    });

    this.heartbeatSub = timer(1000, 5000).pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.logger.debug('Heartbeat send');
      this.send('heartbeat', { time: Date.now().toString() });
    });

    this.wsSub = share$.pipe(
      untilDestroyed(this),
    ).subscribe(
      (response: any) => this.onMessage(response),
      (error) => {
        console.error('WebSocket:', error);

        this.initWebSocket();
      },
      () => console.log('WebSocket: Complete'),
    );
  }

  private onMessage(response: { type: string, data: any }): void {
    switch (response.type) {
      case 'loginSuccessful':
        this.logger.debug('Received: Login successful.');

        this.status$.next(true);
        this.connectionCount$.next(this.connectionCount$.getValue() + 1);
        this.lastConnectionAt$.next(new Date());

        this.restaurantTable.table$.pipe(
          filter((table) => !!table),
          take(1),
          takeUntil(this.status$.pipe(
            filter((connected) => !connected),
          )),
          untilDestroyed(this),
        ).subscribe(() => {
          if (this.callWaiter || this.callWaiterToPay || this.callWaiterToRepeat) {
            this.sendCallWaiter();
            this.sendCallWaiterPushNotification();
          }

          this.dwallIntercom.call('battery.level.get');
        });
        break;

      case 'loginError':
      case 'deleteTable':
        this.logger.debug('Received: ' + response.type === 'loginError' ? 'Login error.' : 'Delete table.');
        this.auth.logout();
        this.ws$.complete();
        break;

      case 'tableInfo':
        this.logger.debug('Received: Table info.', response.data);
        this.updateTableInfo(response.data);
        break;

      case 'echo':
        if (response.data?.status === false) {
          this.logger.debug('Received echo: Cancel Call Waiter.', response.data);
          this.restaurantTable.callWaiter$.next(false);
        }

        if (response.data?.pay === false) {
          this.logger.debug('Received echo: Cancel Call Waiter To Pay.', response.data);
          this.restaurantTable.callWaiterToPay$.next(false);
        }

        if (response.data?.repeat === false) {
          this.logger.debug('Received echo: Cancel Call Waiter To Repeat.', response.data);
          this.restaurantTable.callWaiterToRepeat$.next(false);
        }

        if (response.data?.getPlaylistItems === true) {
          this.logger.debug('Received echo: Get playlist items.', response.data);
          this.dwallIntercom.call('playlist.get_items');
        }

        if (response.data?.deviceTurnOff === true) {
          this.logger.debug('Received echo: Turn off device.', response.data);
          this.dwallIntercom.call('device.turnOff');
        }
        break;

      case 'heartbeat':
        this.logger.debug('Received: Heartbeat', response.data?.time);
        break;

      default:
        this.logger.debug('Received: Unknown message', response);
        break;
    }
  }

}
