import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined, isUndefined } from '../utils';
import { AuthService } from './auth.service';
import { FileCacheService } from './file-cache.service';
import { KeyValueStorageService } from './key-value.storage.service';
import { WatchdogService } from './watchdog.service';

@UntilDestroy()
@Injectable()
export class InvoiceQrcodeImageService {

  public readonly url$ = new BehaviorSubject<SafeUrl | null>(null);
  protected readonly urlStorageKey = 'invoiceQrcodeImage';
  private readonly logger = this.watchdog.tag('Invoice Qrcode Image Service', 'green');

  constructor(
    private readonly watchdog: WatchdogService,
    private readonly auth: AuthService,
    private readonly filesCache: FileCacheService,
    private readonly keyValueStorage: KeyValueStorageService,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.get().pipe(
      untilDestroyed(this),
    ).subscribe((safeUrl) => {
      this.logger.info('Invoice QR-Code initialized');
      this.url$.next(safeUrl);
    });

    this.auth.logouted$.pipe(
      switchMap(() => this.clear()),
      tap(() => this.logger.info('Cleared invoice QR-Code on logout')),
      untilDestroyed(this),
    ).subscribe();
  }

  public get(): Observable<SafeUrl> {
    const processSafeUrls = (url: string) => this.filesCache.getFile(url).pipe(
      map((file) => this.domSanitizer.bypassSecurityTrustUrl(
        file.objectUrl,
      )),
    );

    return this.keyValueStorage.get<string>(this.urlStorageKey).pipe(
      filter(isDefined),
      map((entry) => entry.value),
      switchMap(processSafeUrls),
    );
  }

  public update(url: string): Observable<SafeUrl> {
    return this.clear().pipe(
      switchMap(() => this.keyValueStorage.set({
        key: this.urlStorageKey,
        value: url,
      })),
      switchMap(() => this.get()),
      tap((safeUrl) => this.url$.next(safeUrl)),
    );
  }

  public clear(): Observable<unknown> {
    return this.keyValueStorage.get<string>(this.urlStorageKey).pipe(
      switchMap((entry) => {
        if (isUndefined(entry)) {
          return of(entry);
        }

        return this.filesCache.delete(entry.value);
      }),
      switchMap(() => {
        return this.keyValueStorage.delete(this.urlStorageKey);
      }),
      tap(() => this.url$.next(null)),
    );
  }

}
