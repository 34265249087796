import moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap, toArray } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined } from '../utils';
import {
  HappyHoursPeriodModel,
  HappyHoursPeriodNowModel,
  HappyHoursPeriodNextModel,
  IHappyHoursPeriod,
  IHappyHoursPeriodRaw
} from '../models';
import { AuthService } from './auth.service';
import { HappyHoursStorageService } from './happy-hours.storage.service';
import { WatchdogService } from './watchdog.service';

@UntilDestroy()
@Injectable()
export class HappyHoursService {

  public readonly timetable$ = new BehaviorSubject<IHappyHoursPeriod[]>([]);
  public readonly status$ = new BehaviorSubject<HappyHoursPeriodNextModel | HappyHoursPeriodNowModel | null>(null);
  private readonly logger = this.watchdog.tag('Happy Hours Service', 'green');

  constructor(
    private readonly watchdog: WatchdogService,
    private readonly auth: AuthService,
    private readonly storage: HappyHoursStorageService,
  ) {
    this.initTimetable();
    this.initLogouted();
    this.initStatus();
  }

  get isNow$(): Observable<boolean> {
    return this.status$.pipe(
      map((hh) => hh instanceof HappyHoursPeriodNowModel),
      distinctUntilChanged(),
    );
  }

  public gatAll(): Observable<IHappyHoursPeriod[]> {
    return this.storage.getAll();
  }

  public updateByCollection(timetable: IHappyHoursPeriodRaw[]): Observable<HappyHoursPeriodModel[]> {
    return this.gatAll().pipe(
      filter((value) => {
        const newValue = timetable.map(HappyHoursPeriodModel.parseRaw);

        return JSON.stringify(value) !== JSON.stringify(newValue);
      }),
      switchMap(() => this.storage.clear()),
      switchMap(() =>
        concat(
          ...timetable.map((hh) => this.storage.set(HappyHoursPeriodModel.parseRaw(hh)).pipe(
            filter(isDefined),
          )),
        ).pipe(
          toArray(),
        ),
      ),
      tap((updatedTimetable) => this.timetable$.next(updatedTimetable)),
    );
  }

  public clear(): Observable<boolean> {
    return this.storage.clear().pipe(
      tap(() => this.timetable$.next([])),
    );
  }

  private initTimetable(): void {
    this.gatAll().pipe(
      untilDestroyed(this),
    ).subscribe((timetable) => {
      this.logger.info('Happy hours initialized', timetable);
      this.timetable$.next(timetable);
    });
  }

  private initLogouted(): void {
    this.auth.logouted$.pipe(
      switchMap(() => this.clear()),
      tap(() => this.logger.info('Cleared happy hours on logout')),
      untilDestroyed(this),
    ).subscribe();
  }

  private initStatus(): void {
    this.timetable$.pipe(
      tap(() => this.logger.info('Happy hours started to watch')),
      switchMap((times) => {
        if (times.length === 0) {
          return of(null);
        }

        return this.setStatus(times);
      }),
      untilDestroyed(this),
    ).subscribe((status) => {
      this.logger.debug('Happy hours status changed', status ? status : 'No happy hours now');
      this.status$.next(status);
    });
  }

  public setStatus(periods: IHappyHoursPeriod[]): Observable<HappyHoursPeriodNextModel | HappyHoursPeriodNowModel | null> {
    const models = periods.map((period) => new HappyHoursPeriodModel(period));

    return timer(0, 1000).pipe(
      map(() => {
        const now = moment();

        const activeNow = models.filter(model => {
          const startDay = model.startDay;
          const endDay = model.endDay;
          const currentDay = moment().day();

          if (endDay < startDay) {
            return currentDay >= startDay || currentDay <= endDay;
          }

          return currentDay >= startDay && currentDay <= endDay;
        }).find((time) => {
          const startDay = time.startDay;
          const startTime = time.timeStart.clone();
          const endDay = time.endDay;
          const endTime = time.timeEnd;

          const currentDay = moment().day();
          const currentTime = moment();

          if (currentDay === startDay && currentTime <= startTime) {
            return false
          }
          else if (currentDay === endDay && currentTime >= endTime) {
            return false;
          }

          return true;
        });

        if (activeNow) {
          return new HappyHoursPeriodNowModel(activeNow);
        }

        const nextPeriod = models
          .filter((model) => {
            const start = model.timeStart.clone().day(model.startDay);
            return now.isBefore(start);
          })
          .sort((a, b) => {
            const startA = a.timeStart.clone().day(a.startDay);
            const startB = b.timeStart.clone().day(b.startDay);
            return startA.diff(startB);
          })[0];

        if (nextPeriod) {
          return new HappyHoursPeriodNextModel(nextPeriod);
        }

        return null;
      })
    )
  }
}
