import { importProvidersFrom } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

interface CoreServiceWorkerConfig {
  enabled: boolean;
  script?: string;
}

export const provideCoreServiceWorker = (config: CoreServiceWorkerConfig) => importProvidersFrom(
  ServiceWorkerModule.register(config.script ?? 'ngsw-worker.js', {
    enabled: config.enabled,
    registrationStrategy: 'registerImmediately',
  }),
);
