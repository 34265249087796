import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DebugFieldComponent, DebugItemsComponent } from '../../ui';
import { SwarmService } from '../../../../services';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

@Component({
  selector: 'core-debug-swarm',
  standalone: true,
  templateUrl: './debug-swarm.component.html',
  styleUrls: ['./debug-swarm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugItemsComponent,
    DebugFieldComponent,
  ],
})
export class DebugSwarmComponent {

  public readonly leader = toSignal(
    this.swarm.leader$.pipe(
      map((leader) => !!leader),
    ), {
      initialValue: false,
    },
  );

  public readonly members = toSignal(
    this.swarm.members$.pipe(
      map((members) => {
        const result: {
          id: string;
          self: boolean;
          leader: boolean;
        }[] = [];

        members.forEach((member, id) => {
          result.push({
            id,
            self: member.self,
            leader: member.leader,
          });
        });

        return result;
      }),
    ),
    {
      initialValue: [],
    },
  );

  constructor(
    private readonly swarm: SwarmService,
  ) {}

}
