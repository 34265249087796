import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EMPTY, from, merge, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { arrayDiff, arrayDiffIdCompare, fromArray, tapSubscribed } from '../utils';
import { ISpecial } from '../models';
import { FileCacheService } from './file-cache.service';
import { SpecialsStorageService } from './specials.storage.service';
import { WatchdogService } from './watchdog.service';

interface ToUpdate<T> {
  origin: T;
  value: T;
}

@UntilDestroy()
@Injectable()
export class SpecialsService {

  private readonly logger = this.watchdog.tag('Specials Service', 'lime');

  constructor(
    private readonly filesCache: FileCacheService,
    private readonly specialsStorage: SpecialsStorageService,
    private readonly watchdog: WatchdogService,
  ) {}

  public sync(specialsWS: ISpecial[]): Observable<ISpecial[]> {
    return this.specialsStorage.getAll().pipe(
      tapSubscribed(() => this.logger.debug('Start sync special')),
      switchMap((currentSpecials) => {
        const add = arrayDiff(specialsWS, currentSpecials, arrayDiffIdCompare);
        const del = arrayDiff(currentSpecials, specialsWS, arrayDiffIdCompare);
        const upd = currentSpecials.reduce<ToUpdate<ISpecial>[]>((acc, origin) => {
          const value = specialsWS.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }

          return acc;
        }, []);

        return merge(
          this.toAdd(add),
          this.toUpdate(upd),
          this.toDelete(del),
        );
      }),
    );
  }

  private toAdd(specials: ISpecial[]): Observable<ISpecial[]> {
    if (specials.length === 0) {
      return of([]);
    }

    return from(specials).pipe(
      tap((special) => this.logger.debug('Special to add', special)),
      mergeMap((special) => this.specialsStorage.set(special)),
      mergeMap((special) => {
        return this.filesCache.getFile(special.content).pipe(
          map(() => special),
        );
      }),
      toArray(),
      tap((special) => this.logger.debug('Special added', special)),
    );
  }

  private toUpdate(specials: ToUpdate<ISpecial>[]): Observable<ISpecial[]> {
    return fromArray(specials).pipe(
      tap((special) => this.logger.debug('Special to update', special)),
      mergeMap(({ origin, value }) => this.specialsStorage.set(value).pipe(
        map((special) => (
          {
            origin,
            special,
          }
        )),
      )),
      mergeMap(({ origin, special }) => {
        if (!special) {
          return EMPTY;
        }

        if (special.content === origin.content) {
          return of(special);
        }

        return this.filesCache.delete(origin.content).pipe(
          mergeMap(() => this.filesCache.getFile(special.content)),
          map(() => special),
        );
      }),
      toArray(),
      tap((special) => this.logger.debug('Special updated', special)),
    );
  }

  private toDelete(specials: ISpecial[]): Observable<ISpecial[]> {
    return fromArray(specials).pipe(
      tap((special) => this.logger.debug('Special to delete', special)),
      mergeMap((special) => this.specialsStorage.delete(special)),
      mergeMap((special) => {
        if (!special) {
          return EMPTY;
        }

        return this.filesCache.delete(special.content).pipe(
          map(() => special),
        );
      }),
      toArray(),
      tap((special) => this.logger.debug('Special deleted', special)),
    );
  }

}
