import { homePageAnimations } from './home-page.animation';
import { Component, DestroyRef } from '@angular/core';
import { NgIf } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, interval, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { InteractionService, MenusService, NetworkService, RestaurantTableService } from '@core';
import { HappyHoursComponent } from '@app/components/happy-hours/happy-hours.component';

@Component({
  standalone: true,
  selector: 'app-home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.scss'],
  imports: [
    NgIf,
    HappyHoursComponent,
    RouterOutlet,
    TranslateModule,
  ],
  animations: [
    homePageAnimations,
  ],
})
export class HomeTabComponent {
  public showAds = false;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly menusService: MenusService,
    private readonly interaction: InteractionService,
    private readonly networkConnection: NetworkService,
  ) {
    this.initShowAds();
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  get isHomePage(): boolean {
    return this.router.url === '/main/home';
  }

  specialOfferOnClick(): void {
    this.menusService.getMenuForCurrentMedia().subscribe((menu) => {
      if (menu) {
        this.router.navigate(['main/home/special-offer']);
      }
    });
  }

  initShowAds(): void {
    this.interaction.idle$.pipe(
      tap((idle) => {
        if (!idle && this.showAds) {
          this.showAds = false;
        }
      }),
      switchMap((idle) => {
        if (idle && this.networkConnection.status$.getValue()) {
          return interval(40000).pipe(
            tap(() => this.showAds = true),
            switchMap(() => timer(10000)),
            tap(() => this.showAds = false),
          );
        }

        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  prepareRoute(outlet: RouterOutlet): unknown {
    return outlet?.activatedRouteData['animation'];
  }

}
