import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WatchdogService } from './watchdog.service';

@Injectable()
export class NetworkService {

  public readonly status$ = new BehaviorSubject<boolean>(true);
  public readonly connectionCount$ = new BehaviorSubject<number>(0);
  public readonly lastConnectionAt$ = new BehaviorSubject<Date | null>(null);
  public readonly lastDisconnectionAt$ = new BehaviorSubject<Date | null>(null);
  private readonly logger = this.watchdog.tag('Connection Status Service', 'red');

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly watchdog: WatchdogService,
  ) {}

  public get isOnline(): boolean {
    return this.status$.getValue();
  }

  public get attempts(): number {
    return this.connectionCount$.getValue();
  }

  public get lastConnectionAt(): Date | null {
    return this.lastConnectionAt$.getValue();
  }

  public get lastDisconnectionAt(): Date | null {
    return this.lastDisconnectionAt$.getValue();
  }

  public initialize(): void {
    this.logger.info('Initializing');

    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false)),
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      this.logger.info(status ? 'Network is online' : 'Network is offline');

      this.status$.next(status);

      if (status) {
        this.connectionCount$.next(this.attempts + 1);
        this.lastConnectionAt$.next(new Date());
      }
      else {
        this.lastDisconnectionAt$.next(new Date());
      }
    });
  }

}
