import { DestroyRef, Inject, Injectable, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, EMPTY, exhaustMap, mergeMap, Observable, of, switchMap, timer, zip } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { delay, delayWhen, distinctUntilChanged, filter, finalize, map, take, tap } from 'rxjs/operators';
import { isFunction, isNull, isNumber, isObject, isString, tapSubscribed } from '../utils';
import {
  CORE_STARTUP_DATE,
  CORE_UPDATE_ACTIVATE_STRATEGY,
  CORE_UPDATE_CHECK_STRATEGY,
  CoreUpdateActivateStrategy,
  CoreUpdateCheckStrategy,
} from '../../core.tokens';
import { WatchdogService } from './watchdog.service';
import { NetworkService } from './network.service';
import { InteractionService } from './interaction.service';

export type AppDataSafe = {
  version: string | 'unknown';
};

export type UpdateStatusNotAvailable = {
  state: 'actual';
  date: Date;
  currentBuild: {
    hash: string;
    version: string | 'unknown';
  };
};

export type UpdateStatusAvailable = {
  state: 'available';
  date: Date;
  currentBuild: {
    hash: string;
    version: string | 'unknown';
  };
};

export type UpdateStatusReady = {
  state: 'ready';
  date: Date;
  currentBuild: {
    hash: string;
    version: string | 'unknown';
  };
  latestBuild: {
    hash: string;
    version: string | 'unknown';
  };
};

export type UpdateStatusFailed = {
  state: 'failed';
  date: Date;
  currentBuild: {
    hash: string;
    version: string | 'unknown';
  };
  error: string;
};

export type UpdateStatus = UpdateStatusNotAvailable | UpdateStatusAvailable | UpdateStatusReady | UpdateStatusFailed;

@Injectable()
export class UpdateService {

  public readonly status$ = new BehaviorSubject<UpdateStatus | null>(null);
  private readonly logger = this.watchdog.tag('Update Service', 'red');

  constructor(
    @Inject(CORE_STARTUP_DATE) private readonly startDate: Date,
    @Inject(CORE_UPDATE_CHECK_STRATEGY) private readonly updateCheckStrategy: CoreUpdateCheckStrategy,
    @Inject(CORE_UPDATE_ACTIVATE_STRATEGY) private readonly updateActivateStrategy: CoreUpdateActivateStrategy,
    private readonly destroyRef: DestroyRef,
    private readonly network: NetworkService,
    private readonly idle: InteractionService,
    private readonly watchdog: WatchdogService,
    @Optional() private readonly serviceWorkerUpdate?: SwUpdate,
  ) {}

  public get state(): UpdateStatus['state'] | null {
    return this.status$.getValue()?.state ?? null;
  }

  public get enabled(): boolean {
    return !!this.serviceWorkerUpdate?.isEnabled;
  }

  public get updateCheckStrategyTitle(): string {
    if (this.updateCheckStrategy === 'manual') {
      return 'Manual';
    }

    if (isNumber(this.updateCheckStrategy)) {
      return `Every ${ this.updateCheckStrategy }ms`;
    }

    if (isFunction(this.updateCheckStrategy)) {
      return 'Custom strategy';
    }

    return this.updateCheckStrategy.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  }

  public get updateActivateStrategyTitle(): string {
    if (
      isObject(this.updateActivateStrategy) &&
      'restart-delay' in this.updateActivateStrategy &&
      isNumber(this.updateActivateStrategy['restart-delay'])
    ) {
      return `Restart with delay ${ this.updateActivateStrategy['restart-delay'] }ms`;
    }

    if (
      isObject(this.updateActivateStrategy) &&
      'restart-when' in this.updateActivateStrategy &&
      isFunction(this.updateActivateStrategy['restart-when'])
    ) {
      return 'Custom strategy';
    }

    if (!isString(this.updateActivateStrategy)) {
      return 'Manual';
    }

    return this.updateActivateStrategy.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  }

  public get updateCheckStrategy$(): Observable<unknown> {
    if (isNumber(this.updateCheckStrategy) && this.updateCheckStrategy > 0) {
      return timer(0, this.updateCheckStrategy).pipe(
        tapSubscribed(() => this.logger.debug(`Auto check every ${ this.updateCheckStrategy }ms.`)),
      );
    }

    if (isFunction(this.updateCheckStrategy)) {
      return this.updateCheckStrategy().pipe(
        tapSubscribed(() => {
          this.logger.debug('Auto check custom strategy executed.');
        }),
      );
    }

    if (isString(this.updateCheckStrategy) && this.updateCheckStrategy.startsWith('every-')) {
      let interval = 10_000;

      switch (this.updateCheckStrategy) {
        case 'every-minute':
          interval = 60_000;
          break;
        case 'every-5-minutes':
          interval = 5 * 60_000;
          break;
        case 'every-10-minutes':
          interval = 10 * 60_000;
          break;
        case 'every-30-minutes':
          interval = 30 * 60_000;
          break;
        case 'every-hour':
          interval = 60 * 60_000;
          break;
        case 'every-2-hours':
          interval = 2 * 60 * 60_000;
          break;
        case 'every-6-hours':
          interval = 6 * 60 * 60_000;
          break;
        case 'every-day':
          interval = 24 * 60 * 60_000;
          break;
      }

      return timer(1000, interval).pipe(
        tapSubscribed(() => this.logger.debug(`Auto check every ${ interval }ms.`)),
      );
    }

    return EMPTY.pipe(
      tapSubscribed(() => this.logger.debug('Auto check is disabled.')),
    );
  }

  public get updateActivateStrategy$(): Observable<unknown> {
    if (this.updateActivateStrategy === 'manual') {
      return of(false).pipe(
        tapSubscribed(() => this.logger.warn('Auto activate is disabled. Please restart the app to apply new version.')),
      );
    }

    if (this.updateActivateStrategy === 'always-restart') {
      return this.restartImmediately();
    }

    if (this.updateActivateStrategy === 'restart-when-idle') {
      return this.restartWhenIdle();
    }

    if (this.updateActivateStrategy === 'restart-when-online') {
      return this.restartWhenOnline();
    }

    if (this.updateActivateStrategy === 'restart-when-offline') {
      return this.restartWhenOffline();
    }

    if (this.updateActivateStrategy === 'restart-when-idle-and-online') {
      return this.restartWhenIdleAndOnline();
    }

    if (this.updateActivateStrategy === 'restart-when-idle-and-offline') {
      return this.restartWhenIdleAndOffline();
    }

    if (
      isObject(this.updateActivateStrategy) &&
      'restart-delay' in this.updateActivateStrategy &&
      isNumber(this.updateActivateStrategy['restart-delay'])
    ) {
      return this.restartWithDelay(this.updateActivateStrategy['restart-delay']);
    }

    if (
      isObject(this.updateActivateStrategy) &&
      'restart-when' in this.updateActivateStrategy &&
      isFunction(this.updateActivateStrategy['restart-when'])
    ) {
      const restartWhen = this.updateActivateStrategy['restart-when'];

      return restartWhen().pipe(
        tapSubscribed(() => this.logger.debug('Auto activate custom strategy executed.')),
        take(1),
        finalize(() => this.restartApp()),
      );
    }

    return of(false);
  }

  public initialize(): void {
    this.logger.info('Initializing');

    if (this.updateCheckStrategy === 'manual') {
      this.logger.debug('Auto check is disabled.');
      return;
    }

    if (!this.enabled) {
      this.logger.debug('Update service is not supported.');
      return;
    }

    this.network.status$.pipe(
      distinctUntilChanged(),
      filter((status) => status),
      switchMap(() => this.updateCheckStrategy$),
      mergeMap(() => this.check()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.serviceWorkerUpdate?.versionUpdates.pipe(
      tap((event) => {
        if (event.type === 'NO_NEW_VERSION_DETECTED' && this.state !== 'ready') {
          const currentBuild = {
            hash: event.version.hash,
            version: this.parseAppData(event.version.appData).version,
          };

          this.status$.next({
            state: 'actual',
            date: new Date(),
            currentBuild,
          });

          this.logger.debug('You are using the latest version.', currentBuild);
        }

        if (event.type === 'VERSION_DETECTED' && this.state !== 'ready') {
          const currentBuild = {
            hash: event.version.hash,
            version: this.parseAppData(event.version.appData).version,
          };

          this.status$.next({
            state: 'available',
            date: new Date(),
            currentBuild,
          });

          this.logger.info('New version available.', currentBuild);
        }

        if (event.type === 'VERSION_INSTALLATION_FAILED' && this.state !== 'ready') {
          const currentBuild = {
            hash: event.version.hash,
            version: this.parseAppData(event.version.appData).version,
          };

          this.status$.next({
            state: 'failed',
            date: new Date(),
            error: event.error,
            currentBuild,
          });

          this.logger.error('Failed to install new version.', event.error, currentBuild);
        }

        if (event.type === 'VERSION_READY') {
          const currentBuild = {
            hash: event.currentVersion.hash,
            version: this.parseAppData(event.currentVersion.appData).version,
          };

          const latestBuild = {
            hash: event.latestVersion.hash,
            version: this.parseAppData(event.latestVersion.appData).version,
          };

          this.status$.next({
            state: 'ready',
            date: new Date(),
            currentBuild,
            latestBuild,
          });

          this.logger.info('New version ready to be installed.', {
            currentBuild,
            latestBuild,
          });
        }
      }),
      filter((event) => event.type === 'VERSION_READY'),
      exhaustMap(() => this.updateActivateStrategy$),
      delayWhen(() => {
        if (Date.now() - this.startDate.getTime() < 60_000) {
          this.logger.debug('App is running for less than 1 minute. Waiting for 1 minute before restart app.');
          return timer(Date.now() - this.startDate.getTime());
        }

        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.serviceWorkerUpdate?.unrecoverable.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((event) => {
      this.logger.warn('App is in unrecoverable state. Restarting app to avoid chunk load issue.', event.reason);
      window.location.reload();
    });
  }

  public check(): Observable<boolean> {
    return fromPromise(this.serviceWorkerUpdate?.checkForUpdate() ?? Promise.resolve(false));
  }

  public restartImmediately(): Observable<boolean> {
    return of(true).pipe(
      tapSubscribed(() => this.logger.debug('Restart app to apply new version.')),
      finalize(() => this.restartApp()),
    );
  }

  public restartWithDelay(delayMs: number): Observable<boolean> {
    return timer(delayMs).pipe(
      tapSubscribed(() => this.logger.debug(`Restart app to apply new version after ${ this.updateActivateStrategy }ms.`)),
      map(() => true),
      finalize(() => this.restartApp()),
    );
  }

  public restartWhenIdle(): Observable<boolean> {
    return this.idle.idle$.pipe(
      tapSubscribed(() => this.logger.debug('Restart app to apply new version when app is idle.')),
      filter((status) => status),
      take(1),
      delay(1000),
      finalize(() => this.restartApp()),
    );
  }

  public restartWhenOnline(): Observable<boolean> {
    return this.network.status$.pipe(
      tapSubscribed(() => this.logger.debug('Restart app to apply new version when network is online.')),
      filter((status) => status),
      take(1),
      delay(1000),
      finalize(() => this.restartApp()),
    );
  }

  public restartWhenOffline(): Observable<boolean> {
    return this.network.status$.pipe(
      tapSubscribed(() => this.logger.debug('Restart app to apply new version when network is offline.')),
      filter((status) => !status),
      take(1),
      delay(1000),
      map(() => true),
      finalize(() => this.restartApp()),
    );
  }

  public restartWhenIdleAndOnline(): Observable<boolean> {
    return zip(this.idle.idle$, this.network.status$).pipe(
      tapSubscribed(() => this.logger.debug('Restart app to apply new version when app is idle and network is online.')),
      filter(([idle, online]) => idle && online),
      take(1),
      delay(1000),
      map(() => true),
      finalize(() => this.restartApp()),
    );
  }

  public restartWhenIdleAndOffline(): Observable<boolean> {
    return zip(this.idle.idle$, this.network.status$).pipe(
      tapSubscribed(() => this.logger.debug('Restart app to apply new version when app is idle and network is offline.')),
      filter(([idle, online]) => idle && !online),
      take(1),
      delay(1000),
      map(() => true),
      finalize(() => this.restartApp()),
    );
  }

  private restartApp(): void {
    this.logger.info('Restarting app to apply new version.');
    window.location.reload();
  }

  private parseAppData(appData: unknown): AppDataSafe {
    if (!isObject(appData) || isNull(appData)) {
      return {
        version: 'unknown',
      };
    }

    return {
      version: (
        'version' in appData && isString(appData.version)
      ) ? appData.version : 'unknown',
    };
  }

}
