import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'core-debug-ui-size',
  standalone: true,
  templateUrl: './debug-size.component.html',
  styleUrls: ['./debug-size.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugSizeComponent {}
