@if (table(); as table) {
  <core-debug-ui-size>
    {{ table.tableName }}
  </core-debug-ui-size>

  <core-debug-ui-items>
    <core-debug-ui-field label="Table ID">{{ table.tableId }}</core-debug-ui-field>
    <core-debug-ui-field label="Table Name">{{ table.tableName }}</core-debug-ui-field>
    <core-debug-ui-field label="Default Language">{{ table.language | uppercase }}</core-debug-ui-field>
    <core-debug-ui-field label="Wifi Name">{{ table.wifiName ?? 'N/A' }}</core-debug-ui-field>
    <core-debug-ui-field label="Wifi Password">{{ table.wifiPassword ?? 'N/A' }}</core-debug-ui-field>
    <core-debug-ui-field label="Menus">{{ table.menus.length }}</core-debug-ui-field>
    <core-debug-ui-field label="Specials">{{ table.specials.length }}</core-debug-ui-field>
    <core-debug-ui-field label="Clickable Medias">{{
        table.clickableMediaConfig
          ? table.clickableMediaConfig.length
          : 0
      }}
    </core-debug-ui-field>
    <core-debug-ui-field label="Integrations">{{
        table.integrations
          ? table.integrations.length
          : 0
      }}
    </core-debug-ui-field>
  </core-debug-ui-items>

  <core-debug-ui-items label="Happy Hours">
    @for (happyHourPeriod of table.happyMenuPeriods; track happyHourPeriod.id; let idx = $index) {
      <core-debug-ui-field [label]="idx.toString()">{{dayName(happyHourPeriod.startDay) +' - '+ happyHourPeriod.startTime }} to {{dayName(happyHourPeriod.endDay) +' - '+ happyHourPeriod.endTime }}
      </core-debug-ui-field>
    } @empty {
      No happy hours
    }
  </core-debug-ui-items>

  <core-debug-ui-items label="Work Schedule">
    @for (workSchedule of table.deviceWorkSchedule; track workSchedule.day) {
      <core-debug-ui-field [label]="dayName(workSchedule.day)">{{ workSchedule.timeFrom }}to {{ workSchedule.timeTo }}
      </core-debug-ui-field>
    } @empty {
      No work schedule
    }
  </core-debug-ui-items>
} @else {
  <core-debug-ui-size>
    No information available
  </core-debug-ui-size>
}
