import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'core-debug-ui-items',
  standalone: true,
  templateUrl: './debug-items.component.html',
  styleUrls: ['./debug-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugItemsComponent {

  public label = input<string>();

}
