import { mainPageAnimation } from './main-page.animation';
import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY, interval, merge, timer } from 'rxjs';
import { RGBA } from 'color-blend/dist/types';
import { switchMap, tap } from 'rxjs/operators';
import {
  AuthService,
  BackgroundPlayerComponent,
  DWallIntercom,
  HappyHoursService,
  InteractionService,
  InvoiceQrcodeImageService,
  IRestaurantTable,
  MenusService,
  NetworkService,
  RestaurantTableService,
  WebsocketService,
  WidgetUiConfigService,
} from '@core';
import { ConfettiComponent } from '@app/components/confetti/confetti.component';
import { TabBarComponent } from '@app/components/tab-bar/tab-bar.component';

@Component({
  standalone: true,
  selector: 'app-main',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [
    mainPageAnimation,
  ],
  imports: [
    ConfettiComponent,
    NgIf,
    RouterOutlet,
    TabBarComponent,
    BackgroundPlayerComponent,
  ],
})
export class MainPageComponent implements OnInit {

  public confetti = false;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly changeRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly auth: AuthService,
    private readonly network: NetworkService,
    private readonly interaction: InteractionService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly widgetUiConfigService: WidgetUiConfigService,
    private readonly happyHoursService: HappyHoursService,
    private readonly menusService: MenusService,
    private readonly invoiceQrcodeImageService: InvoiceQrcodeImageService,
    private readonly dwallIntercom: DWallIntercom,
    private readonly webSocketService: WebsocketService,
  ) {}

  get widgetUi(): WidgetUiConfigService {
    return this.widgetUiConfigService;
  }

  get userInteracts(): boolean {
    return this.interaction.interacting;
  }

  get isOnline(): boolean {
    return this.network.isOnline;
  }

  get isWebSocketConnected(): boolean {
    return this.webSocketService.isConnected;
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  get table(): IRestaurantTable | null {
    return this.restaurantTableService.table$.getValue();
  }

  get tableId(): number | null {
    return this.table?.tableId ?? null;
  }

  ngOnInit(): void {
    this.changeRef.detectChanges();

    this.initWorkerCallWaiter();
    this.initWorkerConfetti();
    this.initDWallIntercom();
  }

  prepareRoute(outlet: RouterOutlet): unknown {
    return outlet?.activatedRouteData['animation'];
  }

  private initWorkerCallWaiter(): void {
    merge(
      this.restaurantTableService.callWaiter$,
      this.restaurantTableService.callWaiterToPay$,
      this.restaurantTableService.callWaiterToRepeat$,
    ).pipe(
      tap(() => this.webSocketService.sendCallWaiter()),
      tap(() => this.webSocketService.sendCallWaiterPushNotification()),
      tap(() => this.diSendCallWaiterAmbientLight()),
      switchMap(() =>
        (
          this.callWaiter || this.callWaiterToPay || this.callWaiterToRepeat
        )
          ? interval(5000).pipe(tap(() => this.webSocketService.sendCallWaiter()))
          : EMPTY,
      ),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private initWorkerConfetti(): void {
    this.happyHoursService.isNow$.pipe(
      tap((yes) => this.confetti = yes),
      switchMap(() => timer(4500)),
      tap(() => this.confetti = false),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private initDWallIntercom(): void {
    this.dwallIntercom.messages$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      switch (message.event) {
        case 'playlist.items':
          this.webSocketService.sendPlaylistItems(message.data);
          break;
        case 'battery.level':
          this.webSocketService.sendBatteryLevel(+message.data.level, message.data.charging);
          break;
      }
    });
  }

  private diSendCallWaiterAmbientLight(): void {
    if (this.callWaiter || this.callWaiterToPay || this.callWaiterToRepeat) {
      let color: RGBA | null = null;

      if (this.callWaiter && this.widgetUi.callWaiterButtonLightColor) {
        color = this.widgetUi.callWaiterButtonLightColor;
      }

      if (this.callWaiterToRepeat && this.widgetUi.anotherRoundButtonLightColor) {
        color = this.widgetUi.anotherRoundButtonLightColor;
      }

      if (this.callWaiterToPay && this.widgetUi.requestBillButtonLightColor) {
        color = this.widgetUi.requestBillButtonLightColor;
      }

      if (color) {
        this.dwallIntercom.call('ambient_light.on', { color });
      }

    }
    else {
      this.dwallIntercom.call('ambient_light.off');
    }
  }

}
