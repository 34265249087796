import { innerFrom } from '../observable/innerFrom';
import { Subject } from '../Subject';
import { operate } from '../util/lift';
import { createOperatorSubscriber } from './OperatorSubscriber';
export function repeatWhen(notifier) {
  return operate((source, subscriber) => {
    let innerSub;
    let syncResub = false;
    let completions$;
    let isNotifierComplete = false;
    let isMainComplete = false;
    const checkComplete = () => isMainComplete && isNotifierComplete && (subscriber.complete(), true);
    const getCompletionSubject = () => {
      if (!completions$) {
        completions$ = new Subject();
        innerFrom(notifier(completions$)).subscribe(createOperatorSubscriber(subscriber, () => {
          if (innerSub) {
            subscribeForRepeatWhen();
          } else {
            syncResub = true;
          }
        }, () => {
          isNotifierComplete = true;
          checkComplete();
        }));
      }
      return completions$;
    };
    const subscribeForRepeatWhen = () => {
      isMainComplete = false;
      innerSub = source.subscribe(createOperatorSubscriber(subscriber, undefined, () => {
        isMainComplete = true;
        !checkComplete() && getCompletionSubject().next();
      }));
      if (syncResub) {
        innerSub.unsubscribe();
        innerSub = null;
        syncResub = false;
        subscribeForRepeatWhen();
      }
    };
    subscribeForRepeatWhen();
  });
}
